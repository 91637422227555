import PaymentSelectors from 'modules/payment/selectors';
import ProductSelectors from 'modules/product/selectors';
import React from 'react';
import {useSelector} from 'react-redux';

import styles from './Footer.module.scss';

function Footer() {
  const paymentInfo = useSelector(PaymentSelectors.payment);
  const pix = paymentInfo?.payment?.pix;
  const credit = paymentInfo?.payment?.credit;
  const statusPayment = useSelector(PaymentSelectors.getStatus);
  const personalizeCheckout = useSelector(ProductSelectors.getPersonalizedCheckout);

  return (
    <div style={{position: (pix || credit) && statusPayment === 200 && !personalizeCheckout?.enabled ? 'fixed' : 'relative'}} className={styles.container_footer}>
      <p>This site is protected by reCAPTCHA and the Google
        <a href='https://policies.google.com/privacy'> Privacy Policy</a> and
        <a href='https://policies.google.com/terms'> Terms of Service</a> apply.
      </p>
    </div>
  );
}

export default Footer;
