import {PaymentActions} from 'modules/payment/redux';
import {postOptions} from 'modules/product/api';
import {all, call, put, takeLatest} from 'redux-saga/effects';

import {ProductActions} from '../product/redux';
import {postLeads} from './api';

export const CustomerActions = {
  submitCustomer: (payload) => {
    return async (dispatch) => {
      const response = await postLeads(payload);
      dispatch(CustomerActions.changeCustomer(payload.customer));
      dispatch(ProductActions.changeSession(response));
    };
  },
  changeCustomer: (customer) => ({type: ActionTypes.CHANGE_CUSTOMER, payload: customer}),
  postLeads: (payload, statusPix, product, payloadOptions) => ({type: ActionTypes.POST_LEADS, payload: payload, statusPix: statusPix, product: product, payloadOptions}),
  setValidActive: (payload) => ({type: ActionTypes.VALID_ACTIVE, payload: payload}),
};

const InitialState = {
  customer: null,
  validActive: false,
};

export const reducer = (state = InitialState, {type, payload}) => {
  switch (type) {
  case ActionTypes.CHANGE_CUSTOMER:
    return {...state, customer: payload};
  case ActionTypes.VALID_ACTIVE:
    return {...state, validActive: payload};
  default:
    return state;
  }
};

const CustomerSagas = {
  asyncPostLeads: function*({payload, statusPix, product, payloadOptions}) {
    yield put(CustomerActions.setValidActive(true));
    const response = yield call(postLeads, payload);
    yield put(CustomerActions.changeCustomer(payload.customer));
    yield put(ProductActions.changeSession(response));

    if (product?.product?.options[0]?.hidden && product?.product?.options?.length === 1) {
      const response = yield call(postOptions, payloadOptions); //eslint-disable-line
      const {rev, session, options} = response;
      if (response?.price) {
        yield put(ProductActions.statusOptions(true));
      }
      yield put(ProductActions.changeSession({rev, session}));
      const paymentInfo = response?.price ? {...response, rev: undefined, session: undefined} : {};
      yield put(ProductActions.changeOptions(Object.assign({options}, paymentInfo)));
    }

    if (statusPix?.status === 'PAYMENT_PENDING') {
      yield put(PaymentActions.generatedPix(false));
      yield put(PaymentActions.changePixExpired(true));
    }
    yield put(CustomerActions.setValidActive(false));
  },
};

export function* saga() {
  yield all([
    yield takeLatest(ActionTypes.POST_LEADS, CustomerSagas?.asyncPostLeads),
  ]);
}

const ActionTypes = {
  CHANGE_CUSTOMER: '@customer/CHANGE_CUSTOMER',
  POST_LEADS: '@customer/POST_LEADS',
  VALID_ACTIVE: '@customer/VALID_ACTIVE',
};
