import {routerMiddleware} from 'connected-react-router';
import {createStore, applyMiddleware, compose} from 'redux';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';

import browserHistory from '../utils/history';
import RootReducer from './reducers/reducers';
import RootSaga from './sagas/sagas';

const sagaMiddleware = createSagaMiddleware();
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const Store = createStore(
  RootReducer(browserHistory),
  composeEnhancer(
    applyMiddleware(
      thunk,
      routerMiddleware(browserHistory),
      sagaMiddleware,
    ),
  ),
);

sagaMiddleware.run(RootSaga);
export default Store;

export {browserHistory};
