
import React, {Suspense, lazy} from 'react';
import {Route} from 'react-router-dom';

import Loading from '../components/Loading';
import {routes} from '../utils/constants';
import Initial from '../views/Initial';

const Fail = lazy(() => import('../views/Fail'));

function Routes() {
  return (
    <Suspense fallback={<Loading />}>
      <Route exact path={routes.INITIAL} component={Initial} />
      <Route exact path={routes.ROOT} component={Fail} />
    </Suspense>
  );
}

export default Routes;
