import useWidth from 'hooks/useWidth';
import React, {useState, useCallback, useEffect, useImperativeHandle, forwardRef, useRef} from 'react';

import IconArrowDown from '../../assets/images/icon-arrow-down.svg';
import styles from './Select.module.scss';

const selectInput = [];

function Select({
  data,
  title,
  message,
  children,
  inputWidth = '100%',
  error,
  disabled = false,
  value,
  optional,
  defaultValue,
  statusColor = false,
  className,
  mandatory = 'false',
  spacingTop = false,
  withoutOpacity = false,
  salesGroup = false,
  onChange = () => { },
  ...rest
}, ref) {
  const [isFocused, setIsFocused] = useState(false);
  const width = useWidth();
  const responsive = width <= 720;
  const selectInputRef = useRef();

  useEffect(() => {
    if (ref) {
      selectInput.push(selectInputRef.current);
    }
  }, [ref]);

  const handleInputFocus = useCallback(() => {
    if (isFocused) {
      setIsFocused(false);
    } else {
      setIsFocused(true);
    }
  }, [isFocused]);

  useImperativeHandle(
    ref,
    () => ({
      resetState: () => {
        selectInput.forEach(input => input.value = '');
      },
    }),
    [],
  );

  return (
    <div className={styles.container_select} style={{width: inputWidth, marginBottom: error ? '10px' : '', marginTop: responsive ? '0px' : spacingTop ? '-15px' : '0px'}}>
      {title && (
        <div className={styles.title_select} style={{opacity: withoutOpacity ? 0.5 : 1}}>
          <p>{title} {mandatory && <b style={{color: 'red'}}>*</b>}</p>
          {optional && <small>(opcional)</small>}
        </div>
      )}
      <div className={`${styles.content_select} ${isFocused ? styles.isFocused : ''} ${disabled ? styles.isDisabled : ''}`}>
        <img src={IconArrowDown} alt='Arrow Down' />
        <select
          style={{color: statusColor ? 'var(--color-light-grey)' : '#575757'}}
          ref={selectInputRef}
          className={className}
          value={value}
          defaultValue={defaultValue}
          onChange={onChange}
          onFocus={handleInputFocus}
          onBlur={handleInputFocus}
          disabled={disabled}
          {...rest}
        >
          {message && <option style={{color: 'var(--color-grey)'}} value=''>{message}</option>}
          {data && data.map((bank, index) => (
            <option key={index} value={salesGroup ? bank.option : bank.value}>{bank.option}</option>
          ))}
        </select>
        {children}
      </div>
      {error && <div className={styles.sectionError}>
        <p className={styles.error_caption}>{error}</p>
      </div>}
    </div>
  );
}

export default forwardRef(Select);
