const ProductSelectors = {
  product(state) {
    return state?.Product?.product;
  },
  session(state) {
    const rev = state?.Product?.product?.rev;
    const session = state?.Product?.product?.session;
    return {rev, session};
  },

  step(state) {
    return state?.Product?.step;
  },

  loading(state) {
    return state?.Product?.loading;
  },

  productLink(state) {
    return state?.Product?.productLink;
  },

  newProduct(state) {
    return state?.Product?.newProduct;
  },

  productType(state) {
    return state?.Product?.productType;
  },
  statusOptions(state) {
    return state?.Product?.statusOptions;
  },
  statusCpf(state) {
    return state?.Product?.statusCpf;
  },
  cpfMessage(state) {
    return state?.Product?.cpfMessage;
  },
  firstProduct(state) {
    return state?.Product?.firstProduct;
  },
  questions(state) {
    return state?.Product?.questions;
  },
  paymentPlan(state) {
    return state?.Product?.paymentPlan;
  },
  orderBump(state) {
    return state?.Product?.orderBump;
  },
  orderBumpStatus(state) {
    return state?.Product?.orderBumpStatus;
  },
  donate(state) {
    return state?.Product?.donate;
  },
  errorDonate(state) {
    return state?.Product?.errorDonate;
  },
  paymentDonate(state) {
    return state?.Product?.paymentDonate;
  },
  getHdnOrigin(state) {
    return state?.Product?.hdnOrigin;
  },
  getDiscountTable(state) {
    return state?.Product?.discountTable;
  },
  getNewValueProduct(state) {
    return state?.Product?.newValueProduct;
  },
  getQuantity(state) {
    return state?.Product?.quantity;
  },
  getSalesInterest(state) {
    return state?.Product?.salesInterest;
  },
  getCondPersonalData(state) {
    return state?.Product?.condPersonalData;
  },
  getTypeCustomer(state) {
    return state?.Product?.typeCustomer;
  },
  getStatusSession(state) {
    return state?.Product?.statusSession;
  },
  getTimer(state) {
    return state?.Product?.timer;
  },
  getReducedCheckout(state) {
    return state?.Product?.reducedCheckout;
  },
  getValidPix(state) {
    return state?.Product?.validPix;
  },
  getValidBump(state) {
    return state?.Product?.validBump;
  },
  getUtm(state) {
    return state?.Product?.utm;
  },
  getConditionalBoleto(state) {
    return state?.Product?.conditionalBoleto;
  },
  getConditionalButton(state) {
    return state?.Product?.conditionalButton;
  },
  getEnabledBoleto(state) {
    return state?.Product?.enabledBoleto;
  },
  getWhatsappConfig(state) {
    return state?.Product?.whatsappConfig;
  },
  getPersonalizedCheckout(state) {
    return state?.Product?.personalizedCheckout;
  },
};

export default ProductSelectors;
