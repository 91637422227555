import {createGlobalStyle} from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    scroll-behavior: smooth; 
    font-family: 'Poppins', sans-serif;
  }

  a {
    text-decoration: none;
    scroll-behavior: smooth; 
  }
  
  body {
    padding: 0px;
    background: var(--background);

    &.modal-open {
      height: 100vh;
      overflow-y: hidden;
    }

    @media screen and (max-width: 720px) {
      padding: 0px;
    }
  }
  
  
  *, input, button {
    border: 0;
    background: none;
    font-family: 'Poppins', sans-serif;
  }
  
  .grecaptcha-badge { 
    visibility: hidden; 
  }

  :root {
    --color-white: #ffffff;
    --background: #f4f7ff;
    --color-input: #EBEBEB;

    --color-primary: #88bd3f;
    --color-secundary: #00619e;

    --color-grey: #575757;
    --color-light-grey: #b1a5a5;
    --color-dark-grey: #4e4e4e;

    --color-disabled: #f9f7f7;

    --color-error: #bf2a2a;
    --color-error-input: #b80101;
    --color-error-payment: #d55959;
    --color-alert: #d8b018;

    --border-input: #bcc4ce;
    --disabled-input: rgba(221, 225, 230, .11);
  }
`;
