import React, {useRef, useState} from 'react';
import NumberFormat from 'react-number-format';
import MaskedInput from 'react-text-mask';

import IconWarning from '../../assets/images/icon-warning.svg';
import {currencyFormatter} from '../../utils/functions';
import styles from './Input.module.scss';

const InputFormat = ({inputRef, mask, type, currency, onChange, guide, onBlur, ...props}) => {
  if (currency) {
    return <NumberFormat {...props} type='tel' thousandSeparator={true} fixedDecimalScale={false} inputMode='numeric' onChange={onChange} onBlur={onBlur} format={currencyFormatter} ref={inputRef} />;
  }
  if (mask) {
    return <MaskedInput {...props} onChange={onChange} onBlur={onBlur} mask={mask} guide={guide} ref={inputRef} type={type} />;
  }
  return <input {...props} onChange={onChange} onBlur={onBlur} ref={inputRef} type={type} />;
};

function Input({
  title,
  error,
  icon,
  mask,
  guide = false,
  type,
  fullHeight,
  mandatory = false,
  inputWidth = '100%',
  noPaste = false,
  currency = false,
  stylePrice = false,
  optional = false,
  normalInput = false,
  onChange = () => {},
  onBlur = () => {},
  withoutOpacity = false,
  ...rest
}) {
  const inputRef = useRef(null);

  const [isFocused, setIsFocused] = useState(false);

  const onPaste = e => {
    noPaste && e.preventDefault();
  };

  const inputClassName = error ? `${styles.inputStyle} ${styles.error}` : styles.inputStyle;

  return (
    <div className={styles.container_input} style={{
      width: inputWidth,
      marginBottom: error ? '10px' : '0px',
      marginTop: normalInput && '0px',
      height: fullHeight ? fullHeight : '',
    }}>
      {title && (
        <div className={styles.title_input} style={{opacity: withoutOpacity ? 0.5 : 1}}>
          <p className={isFocused ? styles.isFocused : ''}>{title} {mandatory && <b style={{color: 'red'}}>*</b>}</p>
          {optional && <small>(opcional)</small>}
        </div>
      )}
      <div
        className={styles.content_input}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      >
        {error && <img style={{marginRight: '5px'}} src={IconWarning} alt='Erro' />}
        <InputFormat
          inputRef={inputRef}
          currency={currency}
          mask={mask}
          type={type}
          onPaste={onPaste}
          onChange={onChange}
          onBlur={onBlur}
          guide={guide}
          className={stylePrice ? styles.inputPrice : inputClassName}
          {...rest}
        />
      </div>
      {error &&
        <div className={styles.positionError}>
          <p className={styles.error_caption}>{error}</p>
        </div>}
    </div>
  );
}

export default Input;
