export const debugCode = {
  C001: {code: 'C001', description: 'Antes do handleValidateForm'},
  C002: {code: 'C002', description: 'Antes do if de validação'},
  C003: {code: 'C003', description: 'Antes do submit do formulário'},
  C004: {code: 'C004', description: 'Erro dentro do useCreditCardSubmission antes de chamar o changePaymentMethod'},
  C005: {code: 'C005', description: 'Erro dentro do useCreditCardSubmission antes de chamar o submitCreditCard'},
  C006: {code: 'C006', description: 'Erro dentro do submit do formulário antes de chamar changeBackForPaymentBoleto'},
  C007: {code: 'C007', description: 'Erro dentro do useCreditCardSubmission na função changeBackForPaymentBoleto'},
  C008: {code: 'C008', description: 'Antes de obter token recaptcha na função do submit do formulário'},
  C009: {code: 'C009', description: 'Antes de setar o loading para false após finalizar a request'},
  C010: {code: 'C010', description: 'Dentro do submitCreditCard antes de mudar o loading do upsell'},
  C011: {code: 'C011', description: 'Dentro do submitCreditCard antes de chamar a request /credit ou /credits'},
  C012: {code: 'C012', description: 'Dentro do submitCreditCard antes de chamar o getNewSession'},
  C013: {code: 'C013', description: 'Dentro do submitCreditCard antes de chamar o getFirstProduct '},
  C014: {code: 'C014', description: 'Dentro do submitCreditCard antes de chamar o setPaymentConfirm'},
  C015: {code: 'C015', description: 'Dentro do submitCreditCard antes de chamar o getUpsell'},
  C016: {code: 'C016', description: 'Dentro do submitCreditCard antes de alterar o status'},
  C017: {code: 'C017', description: 'Dentro do submitCreditCard antes de disparar changeSession'},
  C018: {code: 'C018', description: 'Dentro do submitCreditCard antes de disparar changeStatusPayment'},
  C019: {code: 'C019', description: 'Dentro do submitCreditCard depois de disparar changeStatusPayment'},
  C021: {code: 'C021', description: 'No useCreditCardSubmission dentro do submit antes de setar o loading'},
  C022: {code: 'C022', description: 'No useCreditCardSubmission dentro do submit depois da request'},
  C023: {code: 'C023', description: 'Erro dentro do useCreditCardSubmission na função changeBackForPaymentBoleto depois de alterar o boolean'},
  C024: {code: 'C024', description: 'Dentro do submitCreditCard antes do try'},
  C025: {code: 'C025', description: 'Dentro do handleSubmitForm antes do preventDefault'},
  C026: {code: 'C026', description: 'Dentro do handleSubmitForm antes de setar o loading'},
  C027: {code: 'C027', description: 'Dentro do handleSubmitForm antes de setar o payload'},
};

export const errorDebugCode = {
  E201: {code: 'E201', description: 'Dentro do submitCreditCard antes de definir as constantes'},
  E202: {code: 'E202', description: 'Dentro do submitCreditCard antes de definir error credit como verdadeiro'},
  E203: {code: 'E203', description: 'Dentro do submitCreditCard antes de disparar getErrorMessage'},
  E204: {code: 'E204', description: 'Dentro do submitCreditCard antes de disparar changeSession'},
  E205: {code: 'E205', description: 'Dentro do submitCreditCard antes de disparar changeStatus'},
  E206: {code: 'E206', description: 'Dentro do submitCreditCard antes de disparar changeStatusDetails'},
  E207: {code: 'E207', description: 'Dentro do submitCreditCard após tratar todos os erros'},
  E208: {code: 'E208', description: 'Dentro do submitCreditCard antes de definir o statusCode'},
};

export const debugCodeInFinally = {
  F301: {code: 'F301', description: 'Dentro do submitCreditCard antes de disparar changeBackForPayment'},
  F302: {code: 'F302', description: 'Dentro do submitCreditCard antes de disparar changePayment'},
  F303: {code: 'F303', description: 'Dentro do submitCreditCard antes de disparar changeStep'},
  F304: {code: 'F304', description: 'Dentro do submitCreditCard antes de disparar setLoadingUpsell'},
  F305: {code: 'F305', description: 'Dentro do submitCreditCard depois de disparar setLoadingUpsell'},
  F306: {code: 'F306', description: 'Dentro do  dentro do submit antes de setar o loading'},
};
