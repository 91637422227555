import {ProductActions} from 'modules/product/redux';
import {STEPS} from 'utils/constants';
import {debugCode, debugCodeInFinally, errorDebugCode} from 'utils/debugCode';

import {postPaymentBoleto, postPaymentCredit, postPaymentCredits, postPaymentPix, postStatusPix} from './api';

export const PaymentActions = {
  changePayment: (payment) => ({type: ActionTypes.CHANGE_PAYMENT, payload: payment}),
  changePaymentMethod: (payment) => ({type: ActionTypes.CHANGE_PAYMENT_METHOD, payload: payment}),
  changeStatusPix: (payload) => ({type: ActionTypes.CHANGE_STATUS_PIX, payload: payload}),
  changeStatus: (statusRequest) => ({type: ActionTypes.CHANGE_STATUS, payload: statusRequest}),
  changeStatusDetails: (payload) => ({type: ActionTypes.CHANGE_STATUS_DETAILS, payload}),
  changeBackForPayment: (payload) => ({type: ActionTypes.CHANGE_BACK_FOR_PAYMENT, payload}),
  changeBackForPaymentBoleto: (payload) => ({type: ActionTypes.CHANGE_BACK_FOR_PAYMENT_BOLETO, payload}),
  submitBoleto: (payload) => {
    return async (dispatch) => {
      try {
        const response = await postPaymentBoleto(payload);
        const {session: newSession, rev, payment} = response;
        dispatch(PaymentActions.changeStatus(200));
        dispatch(ProductActions.changeSession({rev, session: newSession}));
        dispatch(PaymentActions.changePayment(payment));
        dispatch(PaymentActions.setPaymentConfirm(true));

        if (payment?.boleto?.url) {
          window.open(payment?.boleto?.url, 'new');
        }

      } catch (err) {
        const {rev, session: newSession} = err?.response?.data;
        if (rev && newSession) {
          dispatch(ProductActions.changeSession({rev, session: newSession}));
        }
        dispatch(PaymentActions.changeStatus(err?.response?.data?.status || err?.response?.status));
      } finally {
        dispatch(ProductActions.changeStep(STEPS.PAYMENT_CONFIRMATION));
      }
    };
  },
  submitCreditCard: (payload, checkedTwoCards, status) => { //eslint-disable-line
    return async (dispatch) => {
      dispatch(PaymentActions.setDebugCode(debugCode.C024.code));
      try {
        dispatch(PaymentActions.setDebugCode(debugCode.C010.code));
        dispatch(PaymentActions.setLoadingUpsell(true));
        dispatch(PaymentActions.setDebugCode(debugCode.C011.code));
        const response = await (checkedTwoCards ? postPaymentCredits(payload) : postPaymentCredit(payload));
        dispatch(PaymentActions.setDebugCode(debugCode.C012.code));
        dispatch(PaymentActions.getNewSession({
          rev: response?.rev,
          session: response.session,
          credit: payload?.credit,
        }));
        dispatch(PaymentActions.setDebugCode(debugCode.C013.code));
        dispatch(ProductActions.getFirstProduct(payload));
        dispatch(PaymentActions.setDebugCode(debugCode.C014.code));
        dispatch(PaymentActions.setPaymentConfirm(true));
        dispatch(PaymentActions.setDebugCode(debugCode.C015.code));
        status && dispatch(PaymentActions.getUpsell(response));
        dispatch(PaymentActions.setDebugCode(debugCode.C016.code));
        dispatch(PaymentActions.changeStatus(200));
        const {rev, session: newSession} = response;
        dispatch(PaymentActions.setDebugCode(debugCode.C017.code));
        dispatch(ProductActions.changeSession({rev, session: newSession}));
        dispatch(PaymentActions.setDebugCode(debugCode.C018.code));
        dispatch(PaymentActions.changeStatusPayment(true));
        dispatch(PaymentActions.setDebugCode(debugCode.C019.code));
      } catch (err) {
        dispatch(PaymentActions.setErrorDebugCode(errorDebugCode.E201.code));
        const {rev, session: newSession, credit} = err?.response?.data?.rev ?
        err?.response?.data : JSON.parse(err?.response?.config?.data);

        dispatch(PaymentActions.setErrorDebugCode(errorDebugCode.E208.code));
        const {status: statusCode} = err?.response?.data?.rev ?
        err?.response?.data : err?.response;

        dispatch(PaymentActions.setErrorDebugCode(errorDebugCode.E202.code));
        dispatch(PaymentActions.getErrorCredit(true));
        dispatch(PaymentActions.setErrorDebugCode(errorDebugCode.E203.code));
        dispatch(PaymentActions.getErrorMessage('Essa opção é obrigatoria'));
        dispatch(PaymentActions.setErrorDebugCode(errorDebugCode.E204.code));
        dispatch(ProductActions.changeSession({rev, session: newSession}));
        dispatch(PaymentActions.setErrorDebugCode(errorDebugCode.E205.code));
        dispatch(PaymentActions.changeStatus(statusCode));
        dispatch(PaymentActions.setErrorDebugCode(errorDebugCode.E206.code));
        dispatch(PaymentActions.changeStatusDetails(credit));
        dispatch(PaymentActions.setErrorDebugCode(errorDebugCode.E207.code));

      } finally {
        dispatch(PaymentActions.setDebugCodeInFinally(debugCodeInFinally.F301.code));
        dispatch(PaymentActions.changeBackForPayment(false));
        dispatch(PaymentActions.setDebugCodeInFinally(debugCodeInFinally.F302.code));
        dispatch(PaymentActions.changePayment({withTwoCards: checkedTwoCards, credit: payload.credit}));
        dispatch(PaymentActions.setDebugCodeInFinally(debugCodeInFinally.F303.code));
        dispatch(ProductActions.changeStep(STEPS.PAYMENT_CONFIRMATION));
        dispatch(PaymentActions.setDebugCodeInFinally(debugCodeInFinally.F304.code));
        dispatch(PaymentActions.setLoadingUpsell(false));
        dispatch(PaymentActions.setDebugCodeInFinally(debugCodeInFinally.F305.code));
      }
    };
  },
  paymentPix: (payload) => ({type: ActionTypes.PAYMENT_PIX, payload: payload}),
  pixApproved: () => {
    return async (dispatch) => {
      dispatch(ProductActions.cancelPing());
      dispatch(PaymentActions.changeStatus(200));
      dispatch(ProductActions.changeStep(5));
      dispatch(PaymentActions.setPaymentConfirm(true));
    };
  },
  submitStatusPix: (payload, hasUpsell = false) => {
    return async (dispatch) => {
      try {
        const response = await postStatusPix(payload);
        dispatch(PaymentActions.changeStatusPix(response));
        if (response.status === 'PAYMENT_APPROVED') {
          if (hasUpsell) {
            dispatch(PaymentActions.setHasUpsell(true));
          }
          dispatch(PaymentActions.getUpsell(response));
          const {session: newSession, rev} = response;
          dispatch(ProductActions.changeSession({rev, session: newSession}));
          dispatch(PaymentActions.paymentPix(response?.upsell?.payment));
          dispatch(PaymentActions.changePixExpired(false));
        }
      } catch (err) {
        dispatch(PaymentActions.changeStatus(err?.response?.data?.status || err?.response?.status));
      }
    };
  },
  submitPix: (payload) => {
    return async (dispatch) => {
      try {
        const response = await postPaymentPix(payload);
        const responseStatus = await postStatusPix(payload);
        dispatch(PaymentActions.changeStatusPix(responseStatus));
        dispatch(PaymentActions.setSessionId(response.session));
        const {session: newSession, rev, payment} = response;
        if (response?.upsell) {
          dispatch(PaymentActions.paymentPix(response?.upsell?.payment));
        } else {
          dispatch(PaymentActions.paymentPix(payment));
        }
        dispatch(PaymentActions.changeStatus(200));
        dispatch(ProductActions.changeSession({rev, session: newSession}));
        dispatch(PaymentActions.changePayment(payment));
        dispatch(PaymentActions.getErrorPix(false));
      } catch (err) {
        dispatch(PaymentActions.getErrorPix(true));
        const {rev, session: newSession} = err?.response?.data;
        if (rev && newSession) {
          dispatch(ProductActions.changeSession({rev, session: newSession}));
        }
        dispatch(PaymentActions.changeStatus(err?.response?.data?.status || err?.response?.status));
      }
    };
  },
  changePixExpired: (payload) => {
    return {type: ActionTypes.CHANGE_PIX_EXPIRED, payload};
  },
  generatedPix: (payload) => {
    return {type: ActionTypes.GENERATED_PIX, payload: payload};
  },
  changeStatusPayment: (payload) => {
    return {type: ActionTypes.STATUS_PAYMENT, payload: payload};
  },
  getUpsell: (payload) => {
    return {type: ActionTypes.GET_UPSELL, payload: payload};
  },
  getNewSession: (payload) => {
    return {type: ActionTypes.GET_NEW_SESSION, payload: payload};
  },
  getErrorMessage: (payload) => {
    return {type: ActionTypes.ERROR_MESSAGE, payload: payload};
  },
  getLoadingPix: (payload) => {
    return {type: ActionTypes.LOADING_PIX, payload: payload};
  },
  getErrorCredit: (payload) => {
    return {type: ActionTypes.ERROR_CREDIT, payload: payload};
  },
  getErrorPix: (payload) => {
    return {type: ActionTypes.ERROR_PIX, payload: payload};
  },
  getStatusUpsell: (payload) => {
    return {type: ActionTypes.STATUS_UPSELL, payload: payload};
  },
  getUpsellFirst: (payload) => {
    return {type: ActionTypes.UPSELL_FIRST, payload: payload};
  },
  setOptions: (payload) => {
    return {type: ActionTypes.OPTIONS, payload: payload};
  },
  setPaymentConfirm: (payload) => {
    return {type: ActionTypes.PAYMENT_CONFIRM, payload: payload};
  },
  setLoadingUpsell: (payload) => {
    return {type: ActionTypes.LOADING_UPSELL, payload: payload};
  },
  setSessionId: (payload) => {
    return {type: ActionTypes.SESSION_ID, payload: payload};
  },
  setValidQuestions: (payload) => {
    return {type: ActionTypes.VALID_QUESTIONS, payload: payload};
  },
  setUpsellEvent: (payload) => {
    return {type: ActionTypes.UPSELL_EVENT, payload: payload};
  },
  setHasUpsell: (payload) => {
    return {type: ActionTypes.HAS_UPSELL, payload: payload};
  },
  setDisableInputs: (payload) => {
    return {type: ActionTypes.DISABLE_INPUTS, payload: payload};
  },
  setViewPixKey: (payload) => {
    return {type: ActionTypes.VIEW_PIX_KEY, payload: payload};
  },
  setDebugCode: (payload) => {
    return {type: ActionTypes.DEBUG_CODE, payload: payload};
  },
  setErrorDebugCode: (payload) => {
    return {type: ActionTypes.ERROR_DEBUG_CODE, payload: payload};
  },
  setDebugCodeInFinally: (payload) => {
    return {type: ActionTypes.DEBUG_CODE_FINALLY, payload: payload};
  },
};

const InitialState = {
  payment: null,
  paymentMethod: null,
  statusPix: null,
  statusDetails: null,
  backForPayment: false,
  backForPaymentBoleto: false,
  pixExpired: false,
  paymentPix: {},
  generatedPix: false,
  statusPayment: false,
  upsell: {},
  newSession: {},
  errorMessage: '',
  loadingPix: false,
  errorCredit: false,
  errorPix: false,
  statusUpsell: true,
  upsellFirst: {},
  options: {},
  paymentConfirm: false,
  loadingUpsell: false,
  sessionId: '',
  hasUpsell: false,
  validQuestions: true,
  upsellEvent: false,
  disableInputs: false,
  viewPixKey: false,
  debugCode: '',
  errorDebugCode: '',
  debugCodeInFinally: '',
};

export const reducer = (state = InitialState, {type, payload}) => {
  switch (type) {
  case ActionTypes.CHANGE_PAYMENT:
    return {...state, payment: payload};
  case ActionTypes.CHANGE_PAYMENT_METHOD:
    return {...state, paymentMethod: payload};
  case ActionTypes.CHANGE_STATUS:
    return {...state, status: payload};
  case ActionTypes.CHANGE_STATUS_DETAILS:
    return {...state, statusDetails: payload};
  case ActionTypes.CHANGE_BACK_FOR_PAYMENT:
    return {...state, backForPayment: payload};
  case ActionTypes.CHANGE_BACK_FOR_PAYMENT_BOLETO:
    return {...state, backForPaymentBoleto: payload};
  case ActionTypes.CHANGE_PIX_EXPIRED:
    return {...state, pixExpired: payload};
  case ActionTypes.PAYMENT_PIX:
    return {...state, paymentPix: payload};
  case ActionTypes.CHANGE_STATUS_PIX:
    return {...state, statusPix: payload};
  case ActionTypes.GENERATED_PIX:
    return {...state, generatedPix: payload};
  case ActionTypes.STATUS_PAYMENT:
    return {...state, statusPayment: payload};
  case ActionTypes.GET_UPSELL:
    return {...state, upsell: payload};
  case ActionTypes.GET_NEW_SESSION:
    return {...state, newSession: payload};
  case ActionTypes.ERROR_MESSAGE:
    return {...state, errorMessage: payload};
  case ActionTypes.LOADING_PIX:
    return {...state, loadingPix: payload};
  case ActionTypes.ERROR_CREDIT:
    return {...state, errorCredit: payload};
  case ActionTypes.ERROR_PIX:
    return {...state, errorPix: payload};
  case ActionTypes.STATUS_UPSELL:
    return {...state, statusUpsell: payload};
  case ActionTypes.UPSELL_FIRST:
    return {...state, upsellFirst: payload};
  case ActionTypes.OPTIONS:
    return {...state, options: payload};
  case ActionTypes.PAYMENT_CONFIRM:
    return {...state, paymentConfirm: payload};
  case ActionTypes.LOADING_UPSELL:
    return {...state, loadingUpsell: payload};
  case ActionTypes.SESSION_ID:
    return {...state, sessionId: payload};
  case ActionTypes.HAS_UPSELL:
    return {...state, hasUpsell: payload};
  case ActionTypes.VALID_QUESTIONS:
    return {...state, validQuestions: payload};
  case ActionTypes.UPSELL_EVENT:
    return {...state, upsellEvent: payload};
  case ActionTypes.DISABLE_INPUTS:
    return {...state, disableInputs: payload};
  case ActionTypes.VIEW_PIX_KEY:
    return {...state, viewPixKey: payload};
  case ActionTypes.DEBUG_CODE:
    return {...state, debugCode: payload};
  case ActionTypes.ERROR_DEBUG_CODE:
    return {...state, errorDebugCode: payload};
  case ActionTypes.DEBUG_CODE_FINALLY:
    return {...state, debugCodeInFinally: payload};
  default:
    return state;
  }
};

const ActionTypes = {
  CHANGE_PAYMENT: '@payment/CHANGE_PAYMENT',
  CHANGE_PAYMENT_METHOD: '@payment/CHANGE_PAYMENT_METHOD',
  CHANGE_STATUS: '@payment/CHANGE_STATUS',
  CHANGE_STATUS_DETAILS: '@payment/CHANGE_STATUS_DETAILS',
  CHANGE_BACK_FOR_PAYMENT: '@payment/CHANGE_BACK_FOR_PAYMENT',
  CHANGE_BACK_FOR_PAYMENT_BOLETO: '@payment/CHANGE_BACK_FOR_PAYMENT_BOLETO',
  CHANGE_PIX_EXPIRED: '@payment/CHANGE_PIX_EXPIRED',
  PAYMENT_PIX: '@payment/PAYMENT_PIX',
  CHANGE_STATUS_PIX: '@payment/CHANGE_STATUS_PIX',
  GENERATED_PIX: '@payment/GENERATED_PIX',
  STATUS_PAYMENT: '@payment/STATUS_PAYMENT',
  GET_UPSELL: '@payment/GET_UPSELL',
  GET_NEW_SESSION: '@payment/GET_UPSELL_DATA',
  ERROR_MESSAGE: '@payment/ERROR_MESSAGE',
  LOADING_PIX: '@payment/LOADING_PIX',
  ERROR_CREDIT: '@payment/ERROR_CREDIT',
  ERROR_PIX: '@payment/ERROR_PIX',
  STATUS_UPSELL: '@payment/STATUS_UPSELL',
  UPSELL_FIRST: '@payment/UPSELL_FIRST',
  OPTIONS: '@payment/OPTIONS',
  PAYMENT_CONFIRM: '@payment/PAYMENT_CONFIRM',
  LOADING_UPSELL: '@payment/LOADING_UPSELL',
  SESSION_ID: '@payment/SESSION_ID',
  HAS_UPSELL: '@payment/HAS_UPSELL',
  VALID_QUESTIONS: '@payment/VALID_QUESTIONS',
  UPSELL_EVENT: '@payment/UPSELL_EVENT',
  DISABLE_INPUTS: '@payment/DISABLE_INPUTS',
  VIEW_PIX_KEY: '@payment/VIEW_PIX_KEY',
  DEBUG_CODE: '@payment/DEBUG_CODE',
  ERROR_DEBUG_CODE: '@payment/ERROR_DEBUG_CODE',
  DEBUG_CODE_FINALLY: '@payment/DEBUG_CODE_FINALLY',
};
