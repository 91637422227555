import config from '../utils/config';

export const routes = {
  ROOT: '/',
  INITIAL: '/:codeAffiliate?/:code',
  VALIDATE: '/:code/validate',
  PERSONAL_DATA: '/:code/personal-data',
  PAYMENT: '/:code/payment',
  ERROR: '/error',
};

export const urls = {
  PRODUCT: `${config.apiBaseUrl}/v1/public/checkout/links/:code`,
  LEADS: `${config.apiBaseUrl}/v1/public/checkout/leads`,
  OPTIONS: `${config.apiBaseUrl}/v1/public/checkout/options`,
  PAYMENT_CREDIT: `${config.apiBaseUrl}/v1/public/checkout/payments/credit`,
  PAYMENT_CREDITS: `${config.apiBaseUrl}/v1/public/checkout/payments/credits`,
  PAYMENT_BOLETO: `${config.apiBaseUrl}/v1/public/checkout/payments/boleto`,
  PAYMENT_PIX: `${config.apiBaseUrl}/v1/public/checkout/payments/pix`,
  PAYMENT_PIX_STATUS: `${config.apiBaseUrl}/v1/public/checkout/payments/pix/status`,
  CHECKLINKS: `${config.apiBaseUrl}/v1/public/checkout/check/links/:affiliate/:id`,
  LINK_DONATION: `${config.apiBaseUrl}/v1/public/checkout/linksdonations`,

  STATES: `${config.countriesBaseUrl}/v1/countries/:country/states`,
  POSTAL_CODE: `${config.countriesBaseUrl}/v1/countries/:country/cep/verify`,
};

export const cardMask = (value) => { //eslint-disable-line
  value = value.replace(/(\d{4})(\d)/, '$1 $2');
  value = value.replace(/(\d{4})(\d)/, '$1 $2');
  value = value.replace(/(\d{4})(\d)/, '$1 $2');

  return value;
};

export const PaymentMethods = {
  CREDITCARD: 'CREDITCARD',
  BOLETO: 'BOLETO',
  PIX: 'PIX',
};

export const STEPS = {
  VALIDATE: 1,
  PAYMENT: 2,
  PAYMENT_CONFIRMATION: 3,
};

export const masks = {
  cpfMask: [
    /[0-9]/, /\d/, /\d/, '.',
    /[0-9]/, /\d/, /\d/, '.',
    /[0-9]/, /\d/, /\d/, '-',
    /\d/, /\d/,
  ],
  cnpjMask: [
    /[0-9]/, /\d/, '.',
    /[0-9]/, /\d/, /\d/, '.',
    /[0-9]/, /\d/, /\d/, '/',
    /\d/, /\d/, /\d/, /\d/, '-',
    /\d/, /\d/,
  ],
  dateMask: [
    /[0-1]/, /[0-9]/, '/',
    /[1-9]/, /[0-9]/,
  ],
  dateBirthDayMask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
  cellPhoneMask: [
    '(', /[1-9]/, /\d/, ')',
    ' ', /\d/, /\d/, /\d/, /\d/,
    /\d/, '-', /\d/, /\d/, /\d/,
    /\d/, /\d/, /\d/, /\d/, /\d/,
  ],
  cardNumber: [
    /\d/, /\d/, /\d/, /\d/,
    ' ', /\d/, /\d/, /\d/,
    /\d/, ' ', /\d/, /\d/,
    /\d/, /\d/, ' ', /\d/,
    /\d/, /\d/, /\d/, ' ',
    /\d/, /\d/, /\d/, /\d/,
  ],
  postalCodeMask: [
    /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/,
    /[0-9]/, '-', /[0-9]/, /[0-9]/,
    /[0-9]/,
  ],
  securityCodeMask: [
    /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/,
  ],
  houseNumber: [
    /[A-Za-z0-9]/, /[A-Za-z0-9]/,
    /[A-Za-z0-9]/, /[A-Za-z0-9]/,
    /[A-Za-z0-9]/, /[A-Za-z0-9]/,
    /[A-Za-z0-9]/, /[A-Za-z0-9]/,
    /[A-Za-z0-9]/, /[A-Za-z0-9]/,
  ],
};
