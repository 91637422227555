import useWidth from 'hooks/useWidth';
import ProductSelectors from 'modules/product/selectors';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {STEPS} from 'utils/constants';

import styles from './Steps.module.scss';

function Steps({step = 0}) {
  const {t} = useTranslation();
  const width = useWidth();
  const typeCostumer = useSelector(ProductSelectors.getTypeCustomer);

  const validTypeCustomer = () => {
    if (typeCostumer === 'natural') {
      return t('VALIDATE_CPF');
    } else if (typeCostumer === 'company') {
      return t('VALIDATE_CNPJ');
    } else {
      return t('VALIDATE_CPF');
    }
  };

  const stepsList = [
    {cod: STEPS.VALIDATE, text: validTypeCustomer()},
    {cod: STEPS.PAYMENT, text: t('PAYMENT')},
    {cod: STEPS.PAYMENT_CONFIRMATION, text: t('CONFIRMATION')},
  ];

  return (
    <div className={styles.container_steps}>
      {stepsList && stepsList.map((stepItem, index) => (
        <section className={styles.section_content} key={index} style={{flex: index === stepsList.length - 1 ? 1 : 2}}>
          <div className={styles.step_item}>
            <div className={`${styles.number_wrapper} ${(step > index) ? styles.number_active : ''}`}>{String(stepItem.cod).padStart(2, '0')}</div>
            {width >= 600 && <div className={`${styles.text_wrapper} ${(step > index) ? styles.text_active : ''}`}>{stepItem.text}</div>}
          </div>
          {index < stepsList.length - 1 && <div className={`${styles.divider} ${(step > index) ? styles.divider_active : ''}`} />}
        </section>
      ))}
    </div>
  );
}

export default Steps;
