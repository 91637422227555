import React from 'react';

import useIsMounted from '../hooks/useIsMounted';

export default function SvgIcon({name: iconName, ...rest}) {
  const ImportedIconRef = React.useRef(null);
  const [loading, setLoading] = React.useState(false);
  const isMounted = useIsMounted();

  React.useEffect(() => {
    setLoading(true);
    const importIcon = async () => {
      try {
        const mod = await import('!!@svgr/webpack?-svgo,+titleProp,+ref!./images/' + iconName + '.svg');
        ImportedIconRef.current = mod.default;
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };
    importIcon();
  }, [iconName, isMounted]);

  if (!loading && ImportedIconRef.current) {
    const {current: ImportedIcon} = ImportedIconRef;
    return <ImportedIcon {...rest} />;
  }

  return null;
}
