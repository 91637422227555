import React from 'react';

import styles from './LoadingInfo.module.scss';

const LoadingInfo = () => {
  return (
    <div className={styles.container}>
      <div className={styles.loading} />
    </div>
  );
};

export default LoadingInfo;
