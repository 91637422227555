import {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';

export default function Portal({children}) {
  const node = useAppendedDiv();
  if (!node) {
    return null;
  }
  return ReactDOM.createPortal(
    children,
    node,
  );
}

function useAppendedDiv() {
  const [node, setNode] = useState(null);

  useEffect(() => {
    const el = document.createElement('div');
    document.body.appendChild(el);
    setNode(el);
    return () => {
      document.body.removeChild(el);
    };
  }, []);

  return node;
}
