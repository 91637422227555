
import Logo from 'assets/images/logo.svg';
import NotFoundImage from 'assets/images/not-found.svg';
import Footer from 'components/Footer';
import React from 'react';
import {useTranslation} from 'react-i18next';

import styles from './Error.module.scss';

function ErrorPage() {
  const {t} = useTranslation();

  return (
    <div className={styles.container_error}>
      <img src={Logo} alt='SCIS' className={styles.logo} />
      <div className={styles.content_error}>
        <img src={NotFoundImage} alt='404' />
        <h1>404</h1>
        <p>{t('PRODUCT_NOT_FOUND')}</p>
      </div>
      <Footer />
    </div>
  );
}

export default ErrorPage;
