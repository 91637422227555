import Elo from 'assets/images/elo-30.svg';
import cardValidator from 'card-validator';
import React, {useState, useEffect} from 'react';
import {usePaymentInputs} from 'react-payment-inputs';
import images from 'react-payment-inputs/images';
import {cardMask} from 'utils/constants';

import styles from './InputCreditCard.module.scss';

function InputCreditCard({
  title,
  error,
  value,
  inputWidth = '100%',
  withoutOpacity = false,
  onChange = () => {},
  onBrand = () => {},
  mask,
  ...rest
}) {
  const {getCardImageProps, getCardNumberProps} = usePaymentInputs();

  const [isFocused, setIsFocused] = useState(false);
  const [cardTypeInfo, setCardTypeInfo] = useState();

  useEffect(() => {
    const card = cardValidator.number(value);
    setCardTypeInfo(card?.card?.type);
    onBrand(card?.card?.type);
  }, [value]); //eslint-disable-line

  return (
    <div
      className={styles.container_input_credit_card}
      style={{width: inputWidth}}
    >
      {title && (
        <div className={styles.title_input_credit_card} style={{opacity: withoutOpacity ? 0.5 : 1}}>
          <p className={isFocused ? styles.isFocused : ''}>{title}</p>
        </div>
      )}
      <div
        className={styles.content_input_credit_card}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      >
        {cardTypeInfo === 'elo' ? (
          <div className={styles.brand}>
            <img src={Elo} style={{width: '1.5em', height: '1em'}} alt='elo' />
          </div>
        ) : (
          <svg {...getCardImageProps({images})} />
        )}
        <input
          {...getCardNumberProps({onChange})}
          {...rest}
          value={cardMask(value)}
        />
      </div>
      {error &&
        <div className={styles.sectionError}>
          <p className={styles.error_caption}>{error}</p>
        </div>
      }
    </div>
  );
}

export default InputCreditCard;
