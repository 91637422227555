import {CustomerMapper} from 'modules/customer/mapper';
import {CustomerMapperCNPJ} from 'modules/customer/mapperCNPJ';
import {CustomerActions} from 'modules/customer/redux';
import {all, call, put, takeEvery} from 'redux-saga/effects';
import {STEPS} from 'utils/constants';

import {routes} from '../../utils/constants';
import browserHistory from '../../utils/history';
import {getCheckLinks, getProduct} from './api';

export const ProductActions = {
  getProduct: (code, params) => ({
    type: ActionTypes.ASYNC_PRODUCT,
    payload: {code, params},
  }),
  changeProduct: (product) => ({
    type: ActionTypes.CHANGE_PRODUCT,
    payload: product,
  }),
  changeStep: (step) => ({type: ActionTypes.CHANGE_STEP, payload: step}),
  changeSession: (payload) => ({type: ActionTypes.CHANGE_SESSION, payload}),
  updateSession: (payload) => ({type: ActionTypes.UPDATE_SESSION, payload}),
  changeOptions: (payload) => ({type: ActionTypes.CHANGE_OPTIONS, payload}),
  cancelPing: () => ({type: ActionTypes.CANCEL_PING}),
  initLoading: (payload) => ({type: ActionTypes.INIT_LOADING, payload}),
  endLoading: (payload) => ({type: ActionTypes.END_LOADING, payload}),
  changeProductLink: (code, idAffiliate, params) => ({
    type: ActionTypes.CHANGE_PRODUCT_LINK,
    payload: {code, idAffiliate, params},
  }),
  getProductType: (payload) => ({type: ActionTypes.PRODUCT_TYPE, payload}),
  statusOptions: (payload) => ({type: ActionTypes.STATUS_OPTIONS, payload}),
  statusCpf: (payload) => ({type: ActionTypes.STATUS_CPF, payload}),
  cpfMessage: (payload) => ({type: ActionTypes.CPF_MESSAGE, payload}),
  getFirstProduct: (payload) => ({type: ActionTypes.FIRST_PRODUCT, payload}),
  getQuestions: (payload) => ({type: ActionTypes.QUESTIONS, payload}),
  getPaymentPlan: (payload) => ({type: ActionTypes.PAYMENTPLAN, payload}),
  getOrderBump: (payload) => ({type: ActionTypes.ORDER_BUMP, payload}),
  getOrderBumpStatus: (payload) => ({
    type: ActionTypes.ORDER_BUMP_STATUS,
    payload,
  }),
  getDonate: (payload) => ({type: ActionTypes.DONATE, payload}),
  getErrorDonate: (payload) => ({type: ActionTypes.ERROR_DONATE, payload}),
  getPaymentDonate: (payload) => ({
    type: ActionTypes.PAYMENT_DONATE,
    payload,
  }),
  setHdnOrigin: (payload) => ({type: ActionTypes.HDN_ORIGIN, payload}),
  setDiscountTable: (payload) => ({type: ActionTypes.DISCOUNT_TABLE, payload}),
  setNewValueProduct: (payload) => ({type: ActionTypes.NEW_VALUE_PRODUCT, payload}),
  setQuantity: (payload) => ({type: ActionTypes.QUANTITY, payload}),
  setSalesInterest: (payload) => ({
    type: ActionTypes.SALES_INTEREST,
    payload,
  }),
  setCondPersonalData: (payload) => ({type: ActionTypes.COND_PERSONAL_DATA, payload}),
  setTypeCustomer: (payload) => ({type: ActionTypes.TYPE_CUSTOMER, payload}),
  setStatusSession: (payload) => ({type: ActionTypes.STATUS_SESSION, payload}),
  setTiming: (payload) => ({type: ActionTypes.TIMER, payload}),
  setReducedCheckout: (payload) => ({type: ActionTypes.REDUCED_CHECKOUT, payload}),
  setValidPix: (payload) => ({type: ActionTypes.VALID_PIX, payload}),
  setValidBump: (payload) => ({type: ActionTypes.VALID_BUMP, payload}),
  setUtm: (payload) => ({type: ActionTypes.SET_UTM, payload}),
  setConditionalBoleto: (payload) => ({type: ActionTypes.CONDITIONAL_BOLETO, payload}),
  setConditionalButton: (payload) => ({type: ActionTypes.CONDITIONAL_BUTTON, payload}),
  setEnabledBoleto: (payload) => ({type: ActionTypes.ENABLED_BOLETO, payload}),
  setWhatsappConfig: (payload) => ({type: ActionTypes.WHATSAPP_CONFIG, payload}),
  getPersonalizedCheckout: (payload) => ({type: ActionTypes.PERSONALIZED_CHECKOUT, payload}),
};

const InitialState = {
  product: null,
  step: 1,
  cancelPing: false,
  loading: false,
  productType: null,
  statusOptions: false,
  statusCpf: false,
  cpfMessage: '',
  firstProduct: {},
  questions: [],
  paymentPlan: [],
  orderBump: {},
  orderBumpStatus: false,
  donate: false,
  errorDonate: false,
  paymentDonate: 0,
  hdnOrigin: undefined,
  discountTable: [],
  newValueProduct: 0,
  quantity: 1,
  salesInterest: false,
  condPersonalData: false,
  typeCustomer: '',
  statusSession: false,
  timer: '',
  reducedCheckout: false,
  validPix: false,
  validBump: false,
  utm: {},
  conditionalBoleto: false,
  conditionalButton: true,
  enabledBoleto: false,
  whatsappConfig: {},
  personalizedCheckout: {},
};

export const reducer = (state = InitialState, {type, payload}) => {
  switch (type) {
  case ActionTypes.CHANGE_PRODUCT:
    return {...state, product: payload};
  case ActionTypes.CHANGE_STEP:
    return {...state, step: payload};
  case ActionTypes.CHANGE_SESSION:
    return {...state, product: {...state.product, ...payload}};
  case ActionTypes.UPDATE_SESSION:
    return state.product.session === payload.session &&
        state.product.rev <= payload.rev
      ? {...state, product: {...state.product, ...payload}}
      : state;
  case ActionTypes.CHANGE_OPTIONS:
    return {
      ...state,
      product: {
        ...state.product,
        product: {...state.product.product, ...payload},
      },
    };
  case ActionTypes.CANCEL_PING:
    return {...state, cancelPing: true};
  case ActionTypes.INIT_LOADING:
    return {...state, loading: true};
  case ActionTypes.END_LOADING:
    return {...state, loading: false};
  case ActionTypes.PRODUCT_TYPE:
    return {...state, productType: payload};
  case ActionTypes.STATUS_OPTIONS:
    return {...state, statusOptions: payload};
  case ActionTypes.STATUS_CPF:
    return {...state, statusCpf: payload};
  case ActionTypes.CPF_MESSAGE:
    return {...state, cpfMessage: payload};
  case ActionTypes.QUESTIONS:
    return {...state, questions: payload};
  case ActionTypes.PAYMENTPLAN:
    return {...state, paymentPlan: payload};
  case ActionTypes.ORDER_BUMP:
    return {...state, orderBump: payload};
  case ActionTypes.ORDER_BUMP_STATUS:
    return {...state, orderBumpStatus: payload};
  case ActionTypes.FIRST_PRODUCT:
    return {...state, firstProduct: {...state.firstProduct, ...payload}};
  case ActionTypes.DONATE:
    return {...state, donate: payload};
  case ActionTypes.ERROR_DONATE:
    return {...state, errorDonate: payload};
  case ActionTypes.PAYMENT_DONATE:
    return {...state, paymentDonate: payload};
  case ActionTypes.HDN_ORIGIN:
    return {...state, hdnOrigin: payload};
  case ActionTypes.DISCOUNT_TABLE:
    return {...state, discountTable: payload};
  case ActionTypes.NEW_VALUE_PRODUCT:
    return {...state, newValueProduct: payload};
  case ActionTypes.QUANTITY:
    return {...state, quantity: payload};
  case ActionTypes.SALES_INTEREST:
    return {...state, salesInterest: payload};
  case ActionTypes.COND_PERSONAL_DATA:
    return {...state, condPersonalData: payload};
  case ActionTypes.TYPE_CUSTOMER:
    return {...state, typeCustomer: payload};
  case ActionTypes.STATUS_SESSION:
    return {...state, statusSession: payload};
  case ActionTypes.TIMER:
    return {...state, timer: payload};
  case ActionTypes.REDUCED_CHECKOUT:
    return {...state, reducedCheckout: payload};
  case ActionTypes.VALID_PIX:
    return {...state, validPix: payload};
  case ActionTypes.VALID_BUMP:
    return {...state, validBump: payload};
  case ActionTypes.SET_UTM:
    return {...state, utm: payload};
  case ActionTypes.CONDITIONAL_BOLETO:
    return {...state, conditionalBoleto: payload};
  case ActionTypes.CONDITIONAL_BUTTON:
    return {...state, conditionalButton: payload};
  case ActionTypes.ENABLED_BOLETO:
    return {...state, enabledBoleto: payload};
  case ActionTypes.WHATSAPP_CONFIG:
    return {...state, whatsappConfig: payload};
  case ActionTypes.PERSONALIZED_CHECKOUT:
    return {...state, personalizedCheckout: payload};
  default:
    return state;
  }
};

const ProductSagas = {
  asyncGetProduct: function* ({payload}) {
    yield put(ProductActions.initLoading(true));
    try {
      const response = yield call(getProduct, payload);
      yield put(
        ProductActions.setConditionalBoleto(response?.product?.boleto?.conditional),
      );
      yield put(
        ProductActions.setEnabledBoleto(response?.product?.boleto?.enabled),
      );
      yield put(
        ProductActions.changeProduct({...response, customer: undefined}),
      );
      yield put(
        CustomerActions.changeCustomer(payload?.params?.document?.type === 'CPF' ? CustomerMapper.get(response.customer) : CustomerMapperCNPJ.get(response.customer)),
      );
      yield put(ProductActions.getOrderBump(response?.orderbump));
      yield put(ProductActions.statusCpf(true));
      yield put(ProductActions.cpfMessage(''));
      yield put(ProductActions.changeStep(STEPS.PAYMENT));
    } catch (error) {
      yield put(ProductActions.statusCpf(false));
      yield put(
        ProductActions.cpfMessage(payload?.params?.document?.type === 'CPF' ? 'Este cpf não é válido para este produto.' : 'Este cnpj não é válido para este produto.'),
      );
      yield put(ProductActions.initLoading(false));
    } finally {
      yield put(ProductActions.endLoading(false));
    }
  },
  asyncCheckLink: function* ({payload}) {
    try {
      const response = yield call(getCheckLinks, payload);
      yield put(ProductActions.setReducedCheckout(response?.reducedCheckout));
      yield put(ProductActions.setDiscountTable(response?.product?.discountTable));
      yield put(ProductActions.setTypeCustomer(response?.typeCustomer));
      yield put(ProductActions.setSalesInterest(response?.salesInterest));
      yield put(ProductActions.getQuestions(response?.questions));
      yield put(ProductActions.getDonate(response?.donations));
      yield put(ProductActions.getProductType(response?.customer));
      yield put(ProductActions.getPaymentPlan(response.paymentPlan));
      yield put(ProductActions.setTiming(response?.timing));
      yield put(ProductActions.changeProduct(response));
      yield put(ProductActions.getFirstProduct(response));
      yield put(ProductActions.setWhatsappConfig(response?.whatsApp));
      yield put(ProductActions.getPersonalizedCheckout(response?.personalizedCheckout));
    } catch (error) {
      const { status: statusCode } = error?.response; //eslint-disable-line
      browserHistory.push(routes.ROOT, {statusCode});
    }
  },
};

export function* saga() {
  yield all([
    yield takeEvery(ActionTypes.ASYNC_PRODUCT, ProductSagas?.asyncGetProduct),
    yield takeEvery(
      ActionTypes.CHANGE_PRODUCT_LINK,
      ProductSagas?.asyncCheckLink,
    ),
  ]);
}

const ActionTypes = {
  ASYNC_PRODUCT: '@product/ASYNC_PRODUCT',
  ORDER_BUMP: '@product/ORDER_BUMP',
  ORDER_BUMP_STATUS: '@product/ORDER_BUMP_STATUS',
  CHANGE_PRODUCT: '@product/CHANGE_PRODUCT',
  CHANGE_STEP: '@product/CHANGE_STEP',
  CHANGE_SESSION: '@product/CHANGE_SESSION',
  UPDATE_SESSION: '@product/UPDATE_SESSION',
  CHANGE_OPTIONS: '@product/CHANGE_OPTIONS',
  CANCEL_PING: '@product/CANCEL_PING',
  INIT_LOADING: '@product/INIT_LOADING',
  END_LOADING: '@product/END_LOADING',
  CHANGE_PRODUCT_LINK: '@product/CHANGE_PRODUCT_LINK',
  PRODUCT_TYPE: '@product/PRODUCT_TYPE',
  STATUS_OPTIONS: '@product/STATUS_OPTIONS',
  STATUS_CPF: '@product/STATUS_CPF',
  CPF_MESSAGE: '@product/CPF_MESSAGE',
  FIRST_PRODUCT: '@product/FIRST_PRODUCT',
  QUESTIONS: '@product/QUESTIONS',
  PAYMENTPLAN: '@product/PAYMENTPLAN',
  DONATE: '@product/DONATE',
  ERROR_DONATE: '@product/ERROR_DONATE',
  PAYMENT_DONATE: '@product/PAYMENT_DONATE',
  HDN_ORIGIN: '@product/HDN_ORIGIN',
  DISCOUNT_TABLE: '@product/DISCOUNT_TABLE',
  NEW_VALUE_PRODUCT: '@product/NEW_VALUE_PRODUCT',
  QUANTITY: '@product/QUANTITY',
  SALES_INTEREST: '@product/SALES_INTEREST',
  COND_PERSONAL_DATA: '@product/COND_PERSONAL_DATA',
  TYPE_CUSTOMER: '@product/TYPE_CUSTOMER',
  STATUS_SESSION: '@product/STATUS_SESSION',
  TIMER: '@product/TIMER',
  REDUCED_CHECKOUT: '@product/REDUCED_CHECKOUT',
  VALID_PIX: '@product/VALID_PIX',
  VALID_BUMP: '@product/VALID_BUMP',
  SET_UTM: '@product/SET_UTM',
  CONDITIONAL_BOLETO: '@product/CONDITIONAL_BOLETO',
  CONDITIONAL_BUTTON: '@product/CONDITIONAL_BUTTON',
  ENABLED_BOLETO: '@product/ENABLED_BOLETO',
  WHATSAPP_CONFIG: '@product/WHATSAPP_CONFIG',
  PERSONALIZED_CHECKOUT: '@product/PERSONALIZED_CHECKOUT',
};
