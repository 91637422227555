import SvgIcon from 'assets/SvgIcon';
import React from 'react';

import styles from './toast-body.module.css';

export const types = {
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
  INFO: 'info',
};

const ToastBody = ({closeToast, message, type}) => (
  <div className={styles.body}>
    {type === types.SUCCESS && <SvgIcon name='CheckToast' />}
    {type === types.ERROR && <SvgIcon name='CancelToast' />}
    {type === types.WARNING && <SvgIcon name='WarnToast' />}
    <div className={styles.grow}>
      <p className={styles.message}>{message}</p>
      <button onClick={closeToast}>
        <SvgIcon className={styles.close} name='Close' />
      </button>
    </div>
  </div>
);

export default ToastBody;
