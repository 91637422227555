import Portal from 'components/Portal';
import React from 'react';
import ReactTooltip from 'react-tooltip';

import styles from './tooltip.module.css';
import './tooltip.css';

export default function Tooltip({text, ...rest}) {
  return (
    <Portal>
      <ReactTooltip
        place='right'
        className={styles.tooltip}
        effect='solid'
        backgroundColor='#ffff'
        {...rest}
      >
        <p>{text}</p>
      </ReactTooltip>
    </Portal>
  );
}
