import {PaymentActions} from 'modules/payment/redux';
import {postOptions} from 'modules/product/api';
import {ProductActions} from 'modules/product/redux';
import {useState} from 'react';
import {useDispatch} from 'react-redux';
import {routes} from 'utils/constants';
import browserHistory from 'utils/history';

function useSubmission() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const submitExtra = async (payload) => {
    try {
      setLoading(true);
      const response = await postOptions(payload);

      if (response?.credit || response?.boleto || response?.pix || response?.price) {
        dispatch(PaymentActions.setValidQuestions(false));
        dispatch(ProductActions.setNewValueProduct(response?.price));
        dispatch(
          ProductActions.setConditionalBoleto(response?.boleto?.conditional),
        );
        dispatch(
          ProductActions.setEnabledBoleto(response?.boleto?.enabled),
        );
      } else {
        dispatch(PaymentActions.setValidQuestions(true));
      }

      dispatch(PaymentActions.getErrorMessage(''));
      const {rev, session, options} = response;
      if (response?.price) {
        dispatch(ProductActions.statusOptions(true));
      }
      dispatch(ProductActions.changeSession({rev, session}));

      const paymentInfo = response?.price ? {...response, rev: undefined, session: undefined} : {};
      dispatch(ProductActions.changeOptions(Object.assign({options}, paymentInfo)));
    } catch (error) {
      const {status: statusCode} = error?.response;
      browserHistory.push(routes.ROOT, {statusCode});
    } finally {
      setLoading(false);
    }
  };
  return {loading, submitExtra};
}

export default useSubmission;
