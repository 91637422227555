import useWidth from 'hooks/useWidth';
import React from 'react';

import IconLoading from '../../assets/images/icon-loading.svg';
import styles from './Button.module.scss';

function Button({withStyle = false, disabled = false, loading = false, outlined = false, children, marginRight = false, conditionalBoleto = false, ...rest}) {
  const width = useWidth();

  const responsive = width <= 720;
  return (
    <button
      type='button'
      disabled={loading ? true : disabled}
      style={{
        width: withStyle ? '200px' : '',
        marginRight: marginRight && !responsive && '20px',
      }}
      className={`${conditionalBoleto ? styles.btnBoleto : styles.btn} ${outlined ? styles.outlined : ''}`}
      {...rest}
    >
      {loading ? <img src={IconLoading} alt='Carregando' /> : children}
    </button>
  );
}

export default Button;
