import {ProductActions} from 'modules/product/redux';
import ProductSelectors from 'modules/product/selectors';
import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useRouteMatch} from 'react-router-dom';

import useIsMounted from './useIsMounted';

function useDataSubmission() {
  const {params} = useRouteMatch();
  const dispatch = useDispatch();
  const isMounted = useIsMounted();
  const [loadingCPF, setLoading] = useState(false);
  const product = useSelector(ProductSelectors.product);
  const hdn = useSelector(ProductSelectors.getHdnOrigin);

  const submit = async (payload) => {
    try {
      setLoading(true);
      const queryString = {
        document: {
          type: payload?.type === 'cpf' ? 'CPF' : 'CNPJ',
          number: payload?.type === 'cpf' ? payload?.document?.replace(/[.\s-]+/g, '') : payload?.document?.replace(/[\D]+/g, ''),
        },
        quantityProducts: payload?.quantityProducts?.toString(),
        rev: product?.rev,
        session: product?.session,
      };

      const queryStringHDN = {
        document: {
          type: payload?.type === 'cpf' ? 'CPF' : 'CNPJ',
          number: payload?.type === 'cpf' ? payload?.document?.replace(/[.\s-]+/g, '') : payload?.document?.replace(/[\D]+/g, ''),
        },
        quantityProducts: payload?.quantityProducts?.toString(),
        rev: product?.rev,
        session: product?.session,
        ...hdn,
      };

      await dispatch(ProductActions.getProduct(params?.code, hdn ? queryStringHDN : queryString));
    } finally {
      isMounted && setLoading(false);
    }
  };

  return {loadingCPF, submit};
}

export default useDataSubmission;
