import React from 'react';
import {withTranslation} from 'react-i18next';
import {Router} from 'react-router-dom';
import './i18n';

import Routes from './routes';
import {browserHistory} from './store/store.config';
import GlobalStyle from './styles/global';

function App() {
  return (
    <>
      <Router history={browserHistory}>
        <Routes />
      </Router>
      <GlobalStyle />
    </>
  );
}

export default withTranslation()(App);
