import {PaymentActions} from 'modules/payment/redux';
import ProductSelectors from 'modules/product/selectors';
import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {PaymentMethods} from 'utils/constants';

function usePixSubmission() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const firstProduct = useSelector(ProductSelectors.firstProduct);

  const submit = async (payload) => {
    try {
      dispatch(PaymentActions.getLoadingPix(true));
      setLoading(true);
      await dispatch(PaymentActions.changePaymentMethod(PaymentMethods.PIX));
      await dispatch(PaymentActions.submitPix(payload, firstProduct));
    } finally {
      setLoading(false);
      dispatch(PaymentActions.getLoadingPix(false));
    }
  };

  return {loading, submit, setLoading};
}

export default usePixSubmission;
