import PaymentSelectors from 'modules/payment/selectors';
import {ProductActions} from 'modules/product/redux';
import ProductSelectors from 'modules/product/selectors';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import styles from './tabs.module.scss';

function Tabs({tabs = [], activedTab = 1, onActive, children, validate, statusAmount}) {
  const product = useSelector(ProductSelectors.product);
  const dispatch = useDispatch();
  const paymentConfirm = useSelector(PaymentSelectors.getPaymentConfirm);

  useEffect(() => {
    if (tabs[activedTab - 1].title === 'Pix') {
      dispatch(ProductActions.setValidPix(true));
    } else {
      dispatch(ProductActions.setValidPix(false));
    }
  }, [activedTab]); //eslint-disable-line

  return (
    <div className={statusAmount ? styles.container_tabs : styles.container_tabs_hidden}>
      <ul>
        {tabs && tabs.filter(tab => tab.enabled).map(({id, icon, iconInactive, title, disabled}) => {
          return (
            <li
              key={id}
              id={id}
              onClick={!product?.product?.credit || (title === 'PIX' && validate) || paymentConfirm || disabled ? () => undefined : () => onActive(id)}
              className={`${activedTab === id ? styles.active : ''}${disabled ? styles.disabled : ''}`}
              style={{backgroundColor: product?.product?.credit ? '' : 'transparent', color: product?.product?.credit ? '' : '#ccc'}}
            >
              {activedTab === id ? <img src={icon} alt='img' /> : <img src={iconInactive} alt='img' />}
              {title}
            </li>
          );
        })}
      </ul>
      <section>
        {children}
      </section>
    </div>
  );
}

export default Tabs;
