const CustomerSelectors = {
  customer(state) {
    return state?.Customer?.customer;
  },
  getValidActive(state) {
    return state?.Customer?.validActive;
  },
};

export default CustomerSelectors;
