import PageConfirmation from 'components/PageConfirmation';
import TryAgain from 'components/TryAgain';
import WhatsAppComponent from 'components/Whatsapp';
import GTM from 'modules/cookies/GTM';
import {MetaPixel} from 'modules/cookies/MetalPixel';
import CustomerSelectors from 'modules/customer/selectors';
import PaymentDetails from 'modules/payment/components/PaymentDetails';
import ProductSelectors from 'modules/product/selectors';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {PaymentMethods, STEPS} from 'utils/constants';

import {PaymentActions} from '../../modules/payment/redux';
import PaymentSelectors from '../../modules/payment/selectors';
import {ProductActions} from '../../modules/product/redux';
import styles from './PaymentConfirmation.module.scss';

function PaymentConfirmation() {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const [confirmationData, setConfirmationData] = useState({});
  const upsell = useSelector(PaymentSelectors.getUpsell);
  const firstProduct = useSelector(ProductSelectors.firstProduct);
  const productInfo = useSelector(ProductSelectors.product);
  const utmParams = useSelector(ProductSelectors.getUtm);
  const paymentInfo = useSelector(PaymentSelectors.payment);
  const boleto = paymentInfo?.payment?.boleto;
  const upsellFirst = useSelector(PaymentSelectors.getUpsellFirst);
  const sessionId = useSelector(PaymentSelectors.getSessionId);
  const upsellEvent = useSelector(PaymentSelectors.getUpsellEvent);
  const [paymentType, setPaymentType] = useState('');
  const orderBumpStatus = useSelector(ProductSelectors.orderBumpStatus);
  const orderBump = useSelector(ProductSelectors.orderBump);
  const customer = useSelector(CustomerSelectors.customer);
  const firstUpsell = useSelector(PaymentSelectors.getUpsellFirst);
  const hasUpsell = useSelector(PaymentSelectors.getHasUpsell);
  const whatsappConfig = useSelector(ProductSelectors.getWhatsappConfig);
  const {paymentConfirm} = paymentInfo;
  const statusBoleto = useSelector(PaymentSelectors.getStatus);

  useEffect(() => {
    setPaymentType(paymentInfo?.paymentMethod);
  }, []); //eslint-disable-line

  useEffect(() => {
    if (!upsellFirst?.upsell) {
      dispatch(PaymentActions.getUpsellFirst(upsell));
    }
  }, []); //eslint-disable-line

  useEffect(() => {
    if (paymentInfo?.status) {
      switch (true) {
      case paymentInfo?.status === 200:
        setConfirmationData({
          title: boleto ? t('PAYMENT_SUCCESS_BOLETO') : t('PAYMENT_SUCCESS'),
          description: boleto && '',
          pix: true,
          classTitle: styles.success_title,
          showButton: false,
        });
        break;
      case paymentInfo?.status === 402:
        setConfirmationData({
          title: boleto ? t('PAYMENT_FAILED') : t('PAYMENT_FAILED_CARD'),
          description: boleto ? t('PAYMENT_FAILED_BOLETO_DESCRIPTION') : t('PAYMENT_FAILED_CARD_DESCRIPTION'),
          classTitle: styles.failed_title,
          showButton: true,
          details: paymentInfo?.statusDetails,
        });
        break;
      case (paymentInfo?.status >= 500 && paymentInfo?.status <= 599):
        setConfirmationData({
          title: t('PAYMENT_FAILED'),
          description: boleto ? t('PAYMENT_FAILED_500_DESCRIPTION_BOLETO') : t('PAYMENT_FAILED_500_DESCRIPTION'),
          classTitle: styles.failed_title,
          showButton: true,
        });
        break;

      default:
        setConfirmationData({
          title: t('PAYMENT_FAILED'),
          description: t('PAYMENT_FAILED_DESCRIPTION'),
          classTitle: styles.failed_title,
          showButton: true,
        });
      }
    }
  }, [paymentInfo]) // eslint-disable-line

  useEffect(() => {
    if (paymentConfirm && firstProduct?.flags?.isRedirect) {
      setTimeout(() => {
        const utmParameters = new URLSearchParams(utmParams);
        const utmString = Object.keys(utmParams).length > 0 ? `&${utmParameters.toString()}` : '';
        const finalURL = `${firstProduct?.flags?.link}${utmString}`;
        window.location.replace(finalURL);
      }, 0);
    }
  }, [paymentConfirm, utmParams, firstProduct]);

  const handleCheckoutBack = (tryWithBoleto = false) => {
    if (paymentInfo?.paymentMethod === PaymentMethods.BOLETO || tryWithBoleto) {
      dispatch(PaymentActions.changeBackForPaymentBoleto(true));
      window.location.reload();
    } else if (paymentInfo?.paymentMethod === PaymentMethods.CREDITCARD) {
      dispatch(PaymentActions.changeBackForPayment(true));
    }
    dispatch(PaymentActions.changeStatus(null));
    dispatch(ProductActions.changeStep(STEPS.PAYMENT));
  };

  useEffect(() => {
    if (!upsell?.upsell) {
      dispatch(PaymentActions.setSessionId(productInfo?.session));
    }
  }, [dispatch, productInfo.session, upsell.upsell]);

  const handlePrice = () => {
    if (firstProduct?.credit?.card?.amount) {
      return firstProduct?.credit?.card?.amount;
    } else if (orderBumpStatus) {
      return (orderBump?.amount);
    } else if (hasUpsell) {
      return firstUpsell?.upsell?.price;
    } else if (firstProduct?.credit?.cards) {
      return firstProduct?.credit?.cards[0].amount + firstProduct?.credit?.cards[1].amount;
    } else {
      return firstProduct?.product?.price;
    }
  };

  const items = [
    {
      sector: 1,
      item_name: orderBumpStatus ? `${firstProduct?.product?.name} + ${orderBump?.name}` : firstProduct?.product?.name,
      price: firstProduct?.credit?.card?.amount ? firstProduct?.credit?.card?.amount : orderBumpStatus && paymentInfo?.paymentMethod === PaymentMethods.PIX ? firstProduct?.orderbump?.amount : handlePrice(),
      quantity: 1,
    },
  ];

  useEffect(() => {
    if (paymentInfo?.paymentMethod === PaymentMethods.BOLETO) {
      dispatch(PaymentActions.setDisableInputs(true));
    }
  }, [paymentInfo.paymentMethod, dispatch]);

  return (
    <>
      {paymentInfo?.paymentMethod === PaymentMethods.CREDITCARD ?
        <>
          <PageConfirmation
            firstProduct={firstProduct}
            overview={true}
            title={t('PAYMENT_SUCCESS')}
            footer1={t('PRODUCT_ID')}
            footer2={firstProduct?.session}
          />
        </>
        :
        <div className={styles.container_payment_confirmation}>

          {(paymentInfo?.paymentMethod === PaymentMethods.BOLETO) && statusBoleto === 200 ? (
            <>
              <PageConfirmation
                firstProduct={firstProduct}
                title={t('PAYMENT_SUCCESS_BOLETO')}
                footer1={boleto && t('BOLETO_LINK')}
                footer2={boleto && boleto?.url}
                typeBoleto={true}
                messagePix={!boleto && t('PAYMENT_SUCCESS_DESCRIPTION')}
              />
              <PaymentDetails title={'OF_BOLETO'} />
            </>
          ) : paymentInfo?.paymentMethod === PaymentMethods.PIX ?
            <>
              <PageConfirmation
                firstProduct={firstProduct}
                title={t('PAYMENT_SUCCESS')}
                footer1={t('PRODUCT_ID')}
                footer2={sessionId}
                overviewIncomplet={true}
              />
            </> : (
              <div style={{width: '100%'}}>
                <TryAgain title={t('PAYMENT_FAILED')} messagePix={!boleto && t('PAYMENT_FAILED_500_DESCRIPTION')} subtitle={confirmationData?.description} onClick={() => boleto ? handleCheckoutBack() : handleCheckoutBack(true)} />
              </div>
            )}
        </div>}

      <MetaPixel metaPixel={firstProduct?.metaPixel} paymentType={paymentType} pixelEvent='purchase' currency='BRL' value={orderBumpStatus && paymentInfo?.paymentMethod === PaymentMethods.PIX ? firstProduct?.orderbump?.amount : handlePrice()} />
      {firstProduct?.metaGTM && <GTM gtagId={firstProduct?.metaGTM} items={items} paymentType={paymentInfo?.paymentMethod} pixelEvent='Purchase' valueTotal={orderBumpStatus && paymentInfo?.paymentMethod === PaymentMethods.PIX ? firstProduct?.orderbump?.amount : handlePrice()}
        orderId={paymentInfo?.paymentMethod === PaymentMethods.CREDITCARD ? firstProduct?.session : sessionId} customerClient={{
          email: customer?.email ? customer?.email : '',
          name: customer?.firstName && customer?.lastName ? `${customer?.firstName} ${customer?.lastName}` : '',
          phone: customer?.phone ? customer?.phone : '',
          birthDay: customer.birthDay ? customer.birthDay : '',
        }} />}

      {upsellEvent && <MetaPixel metaPixel={firstProduct?.metaPixel} paymentType={paymentType} pixelEvent='purchase' currency='BRL' value={handlePrice()} />}
      {firstProduct?.metaGTM && upsellEvent && <GTM gtagId={firstProduct?.metaGTM} paymentType={paymentInfo?.paymentMethod} items={items} pixelEvent='Purchase' valueTotal={upsell?.upsell?.price}
        orderId={paymentInfo?.paymentMethod === PaymentMethods.CREDITCARD ? firstProduct?.session : sessionId} customerClient={{
          email: customer?.email ? customer?.email : '',
          name: customer?.firstName && customer?.lastName ? `${customer?.firstName} ${customer?.lastName}` : '',
          phone: customer?.phone ? customer?.phone : '',
          birthDay: customer.birthDay ? customer.birthDay : '',
        }} />}
      {whatsappConfig?.enabled && <WhatsAppComponent contact={whatsappConfig?.contact} message={whatsappConfig?.message} />}
    </>
  );
}

export default PaymentConfirmation;
