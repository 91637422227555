import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

import ptbr from './translations/ptBR';

const resources = {
  'pt-BR': {
    translation: ptbr,
  },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'pt-BR',
    fallbackLng: 'pt-BR',
    fixLng: true,
    load: 'current',
    keySeparator: false,

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
