import Bag from 'assets/images/Bag.svg';
import Button from 'components/Button';
import useCreditCardSubmission from 'hooks/useCreditCardSubmission';
import useHDNParameters from 'hooks/useHDNParameters';
import useUTMParameters from 'hooks/useUTMParameters';
import CustomerSelectors from 'modules/customer/selectors';
import Pix from 'modules/payment/components/Pix';
import {PaymentActions} from 'modules/payment/redux';
import PaymentSelectors from 'modules/payment/selectors';
import ProductSelectors from 'modules/product/selectors';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {PaymentMethods} from 'utils/constants';
import {convertToCurrency, obterTokenRecaptcha} from 'utils/functions';
import PaymentConfirmation from 'views/PaymentConfirmation';

import Logo from '../../assets/icons/cispay-logo.svg';
import styles from './styles.module.scss';

export const ConfirmationPayment = ({logo = true}) => {
  const [showUpsell, setShowUpsell] = useState(true);
  const upsell = useSelector(PaymentSelectors.getUpsell);
  const firstProduct = useSelector(ProductSelectors.firstProduct);
  const newSession = useSelector(PaymentSelectors.getNewSession);
  const customer = useSelector(CustomerSelectors.customer);
  const options = useSelector(PaymentSelectors.getOptions);
  const UTMParameters = useUTMParameters();
  const HDNParameters = useHDNParameters();
  const dispatch = useDispatch();
  const {submit} = useCreditCardSubmission();
  const loadingUpsell = useSelector(PaymentSelectors.getLoadingUpsell);
  const paymentInfo = useSelector(PaymentSelectors.payment);

  const status = false; //eslint-disable-line

  const handleSubmitCredit = async () => {
    try {
      const token = await obterTokenRecaptcha();
      const payload = {
        session: newSession?.session,
        rev: newSession?.rev,
        customer,
        token,
        options: {...options, ...UTMParameters, ...HDNParameters},
        credit: {
          card: {
            ...newSession?.credit?.card,
            amount: upsell?.upsell?.price,
          },
          installments: upsell?.product?.credit?.installments?.useOriginalInstallments ? newSession?.credit?.installments : 1,
        },
      };

      await dispatch(PaymentActions.changePaymentMethod(PaymentMethods.CREDITCARDUPSELL));
      await submit(payload, status, firstProduct);
      setShowUpsell(false);
      dispatch(PaymentActions.getStatusUpsell(false));
    } catch (error) {
      console.log(error); //eslint-disable-line
    }
  };

  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }, []);

  return (
    <div className={styles.container}>
      {logo && <div className={styles.headerImages}>
        <div>
          <img className={styles.cispay} src={Logo} alt='Logo CISPAY' />
        </div>
      </div>}

      <div className={`${styles.main} ${!logo ? styles.noLogo : ''}`}>
        <div className={styles.position}>
          <PaymentConfirmation />
        </div>
      </div>

      {showUpsell && !!upsell?.upsell && <div className={styles.upsell_container}>
        <div className={styles.upsell_header}>
          <img src={Bag} className={styles.imgBag} alt='imagem orderbump' /> {upsell?.upsell?.question?.title}
        </div>

        <div className={styles.info}>

          <div className={styles.firstSpan}>
            Olá, vimos que você acabou de comprar o <span className={styles.font_green}>{firstProduct?.product?.name}</span>.
          </div>

          <div className={styles.secondSpan}>
            tenho uma excelente notícia para te dar: garantindo hoje o <span className={styles.font_green}>{upsell?.upsell?.name}</span> <br />  você paga APENAS <span className={styles.font_green}>{convertToCurrency(upsell?.upsell?.price)}</span>.
          </div>
        </div>

        {paymentInfo?.paymentMethod === PaymentMethods.CREDITCARD ?
          <div className={styles.buttons_up}>
            <Button onClick={() => setShowUpsell(false)} className={styles.button_no}>{upsell?.upsell?.question?.no}</Button>
            <Button loading={loadingUpsell} onClick={() => handleSubmitCredit()} className={styles.button_yes}>{upsell?.upsell?.question?.yes}</Button>
          </div> : <div style={{width: '95%'}}><Pix /></div>
        }

      </div>}
    </div>
  );
};

export default ConfirmationPayment;
