import {Post} from '../../utils/apiBase';
import {urls} from '../../utils/constants';

export const postPaymentCredit = (payload) => {
  return Post(urls.PAYMENT_CREDIT, payload);
};

export const postPaymentCredits = (payload) => {
  return Post(urls.PAYMENT_CREDITS, payload);
};

export const postPaymentBoleto = (payload) => {
  return Post(urls.PAYMENT_BOLETO, payload);
};

export const postPaymentPix = (payload) => {
  return Post(urls.PAYMENT_PIX, payload);
};

export const postStatusPix = (params) => {
  return Post(urls.PAYMENT_PIX_STATUS, params);
};
