import i18next from 'i18next';
import ProductSelectors from 'modules/product/selectors';
import {useState} from 'react';
import {useSelector} from 'react-redux';
import {convertToCents, convertToCurrency} from 'utils/functions';

import {CardMapper} from '../mappers';

function usePaymentData(initialValue) {
  const productInfo = useSelector(ProductSelectors.product);
  const [paymentData, changePaymentData] = useState(initialValue);
  const orderBumpStatus = useSelector(ProductSelectors.orderBumpStatus);
  const newValueProduct = useSelector(ProductSelectors.getNewValueProduct);
  const salesInterest = useSelector(ProductSelectors.getSalesInterest);
  const [statusInstall, setStatusInstall] = useState(false);

  function setPaymentData(fieldPath, value) {
    const installmentsB = orderBumpStatus ? productInfo?.product?.credit?.installmentsBump : productInfo?.product?.credit?.installments;

    const installmentsOptions = installmentsB?.map(val => ({
      value: val.installments,
      option: `${val.installments}x ${convertToCurrency(val.installmentAmount)} (${i18next.t('TOTAL')}: ${convertToCurrency(val.price)})`,
    }));

    const newValue = setIn({...paymentData}, fieldPath, value);
    const firstValue = convertToCents(newValue?.firstCard?.value?.toString());

    if (!paymentData.installments) {
      newValue.installments = installmentsB?.map(val => ({
        value: val.installments,
        option: `${val.installments}x ${convertToCurrency(statusInstall ? (firstValue / val.installments) : val.installmentAmount)} (${i18next.t('TOTAL')}: ${convertToCurrency(statusInstall ? firstValue : val?.price)})`,
      }));
    }

    const selectedInstallment = installmentsB?.filter(({installments}) => Number(installments) === Number(newValue?.firstCard?.installment));

    if (fieldPath === 'checkedTwoCards' && value === true) {
      newValue.secondCard = CardMapper.from();
    }

    if (fieldPath === 'setStatusInstall') {
      setStatusInstall(value);
    }

    if (fieldPath === 'checkedTwoCards' && value === false) {
      const firstCardValue = convertToCents(newValue?.firstCard?.value?.toString());
      newValue.installments = installmentsB?.map(val => ({
        value: val.installments,
        option: `${val.installments}x ${convertToCurrency(statusInstall ? (firstCardValue / val.installments) : val.installmentAmount)} (${i18next.t('TOTAL')}: ${convertToCurrency(val.price)})`,
      }));

      let cardValue = 0;
      if (orderBumpStatus) {
        if (productInfo?.product.options.length === 0) {
          cardValue = productInfo?.orderbump?.amount;
        } else {
          cardValue = productInfo?.product?.orderbump?.amount;
        }
      }

      newValue.installments = installmentsOptions;
      newValue.firstCard.value = convertToCurrency(orderBumpStatus ? cardValue?.toString() : newValueProduct ? newValueProduct : productInfo?.product?.price);
      newValue.secondCard = null;
      setStatusInstall(false);
    }
    if (fieldPath === 'firstCard.value' && paymentData?.checkedTwoCards) {
      const firstCardValue = convertToCents(newValue?.firstCard?.value?.toString());
      newValue.installments = installmentsB?.map(val => ({
        value: val.installments,
        option: `${val.installments}x ${convertToCurrency(firstCardValue / val.installments)} (${i18next.t('TOTAL')}: ${convertToCurrency(firstCardValue)})`,
      }));
    }

    if (productInfo?.product?.credit?.installments.length <= 1) {
      newValue.firstCard.installment = 1;
    }

    if (fieldPath === 'firstCard.value' && value) {
      setStatusInstall(true);
    }

    if (fieldPath === 'firstCard.installment') {
      const {firstCard, secondCard} = newValue;
      const {installment} = firstCard;

      let cardValue = 0; //eslint-disable-line
      if (orderBumpStatus) {
        if (productInfo?.product.options.length === 0) {
          cardValue = productInfo?.orderbump?.amount;

        } else {
          cardValue = productInfo?.product?.orderbump?.amount;
        }
      }

      const firstCardValue = convertToCents((newValue.firstCard.value).toString());
      newValue.installments = installmentsB?.map((val) => ({
        value: val.installments,
        option: `${val.installments}x ${convertToCurrency(statusInstall ? (firstCardValue / val.installments) : val.installmentAmount)} (${i18next.t('TOTAL')}: ${convertToCurrency(statusInstall ? firstCardValue : val?.price)})`,
      }));

      if (installment?.length && (convertToCents(secondCard?.value) === 0)) {
        if (selectedInstallment?.length) {
          newValue.firstCard.value = convertToCurrency(`${orderBumpStatus ? productInfo?.orderbump?.amount : firstCardValue}`);
        }
      }
    }

    if (newValue.secondCard) {
      let price = (orderBumpStatus ? productInfo?.orderbump?.amount : newValueProduct ? newValueProduct : productInfo?.product?.price);
      if (salesInterest && installmentsB) {
        price = installmentsB[parseInt(newValue?.firstCard?.installment) - 1]?.price || price;
      }

      const firstCardValue = convertToCents(newValue?.firstCard?.value?.toString());
      newValue.secondCard.value = convertToCurrency((price - firstCardValue).toString());
      newValue.secondCard.installment = newValue?.firstCard?.installment !== ''
        ? `${newValue?.firstCard?.installment}x ${convertToCurrency(convertToCents(newValue?.secondCard?.value) / newValue?.firstCard?.installment)} (${i18next.t('TOTAL')}: ${newValue.secondCard.value})`
        : '';
    } else {
      if (selectedInstallment && selectedInstallment.length !== 0) {
        newValue.firstCard.value = convertToCurrency(`${selectedInstallment[0]?.price}`);
      }
    }

    changePaymentData(newValue);
  }

  return [paymentData, setPaymentData];
}

export default usePaymentData;

function setIn(obj = {}, path = '', value) {
  if (!path) {
    return value;
  }

  const [field, ...rest] = path.split('.');

  return {
    ...obj,
    [field]: setIn(obj[field], rest.join('.'), value),
  };
}
