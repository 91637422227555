import {formatCurrency} from '@brazilian-utils/brazilian-utils';
import ProductSelectors from 'modules/product/selectors';
import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import Logo from '../../assets/icons/cispay-logo.svg';
import Pix from '../../modules/payment/components/Pix';
import styles from './pixKey.module.scss';

export default function PixKeyPage({logo = true, personalized}) {
  const {t} = useTranslation();

  const cartProduct = useSelector(ProductSelectors.product);
  const orderBumpStatus = useSelector(ProductSelectors.orderBumpStatus);
  const productValue = formatCurrency((orderBumpStatus ? cartProduct?.orderbump?.amount : cartProduct?.product?.price) / 100);
  const orderBumpName = orderBumpStatus ? `+ ${cartProduct?.orderbump?.name}` : '';

  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }, []);

  return (
    <div className={`${styles.container} ${personalized ? styles.personalized : ''}`}>
      {logo && <img className={styles.logo} src={Logo} alt='Logo' />}

      <header className={styles.header}>
        <div className={styles.titles}>
          <h1>{cartProduct?.product?.name} {orderBumpName}</h1>
          <h2>{`R$ ${productValue}`}</h2>
        </div>

        <p className={styles.session}>
          {`${t('PRODUCT_ID')}  ${cartProduct?.session}`}
        </p>
      </header>
      <div className={styles.content}>
        <Pix />
      </div>

    </div>
  );
}
