import Pitch from 'components/Pitch';
import ProductSelectors from 'modules/product/selectors';
import React from 'react';
import {useSelector} from 'react-redux';
import {useLocation} from 'react-router';

import styles from './PitchComponent.module.scss';

export default function PitchComponent() {
  const personalizedCheckoutData = useSelector(ProductSelectors.getPersonalizedCheckout);
  const loc = useLocation();
  const searchParams = new URLSearchParams(loc.search);

  const urlBannerTop = searchParams.get('urlBannerTop') === 'null' ? null : searchParams.get('urlBannerTop');
  const urlBannerSide = searchParams.get('urlBannerSide') === 'null' ? null : searchParams.get('urlBannerSide');
  const backgroundColor = searchParams.get('backgroundColor');
  const previewMode = Boolean(searchParams.get('preview'));

  if ((personalizedCheckoutData && personalizedCheckoutData.enabled) || previewMode) {
    return (
      <PitchCustom
        background={previewMode ? backgroundColor : personalizedCheckoutData?.backgroundColor}
        urlBannerSide={previewMode ? urlBannerSide : personalizedCheckoutData?.urlBannerSide}
        urlBannerTop={previewMode ? urlBannerTop : personalizedCheckoutData?.urlBannerTop}
        previewMode={previewMode}
      />
    );
  }

  return <Pitch />;
}

const PitchCustom = ({background, urlBannerTop, urlBannerSide, previewMode}) => {

  return (
    <div className={styles.main} style={{background: background}}>
      <div className={styles.firstBanner}>
        {urlBannerTop && <img src={urlBannerTop} alt='Banner promo' className={styles.bannerTop} />}
      </div>
      <div className={`${urlBannerSide ? styles.containerPromoVertical : ''}`}>
        <div className={styles.content}>
          <Pitch logo={!urlBannerTop} headerClassName={`${urlBannerTop ? styles.header : ''}`} accordionClassName={styles.pitchCustom} previewMode={previewMode} personalized />
        </div>
        {urlBannerSide && <img src={urlBannerSide} alt='Banner promo vertical' className={styles.bannerPromoVertical} />}
      </div>
    </div>
  );
};
