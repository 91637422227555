
import ExpiredTimer from 'assets/icons/Tempo-expirado.svg';
import Logo from 'assets/images/logo.svg';
import React from 'react';
import {useTranslation} from 'react-i18next';

import styles from './Error.module.scss';

function ErrorTemp({logo}) {
  const {t} = useTranslation();

  return (
    <div className={styles.container_error}>
      {logo && <img src={Logo} alt='SCIS' className={styles.logo} />}
      <div className={styles.content_error}>
        <img src={ExpiredTimer} alt='404' />
        <p>{t('EXPIRED_TIMER')}</p>
      </div>
    </div>
  );
}

export default ErrorTemp;
