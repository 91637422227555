import React from 'react';

export const MetaPixel = ({metaPixel, pixelEvent, currency, value, contentCategory, contentName, paymentType = ''}) => {

  if (metaPixel) {
    return (
      <img
        height='1'
        width='1'
        alt='pixel facebook'
        style={{display: 'none'}}
        src={`https://www.facebook.com/tr?id=${metaPixel}&ev=${pixelEvent}${value ? `&value=${value / 100}` : ''}${
        currency ? `&currency=${currency}` : ''
        }${contentCategory ? `&content_category=${contentCategory}` : ''}
        ${pixelEvent === 'purchase' && `&cd[payment_type]=${paymentType}`}
        ${contentName ? `&content_name=${contentName}` : ''}`}
      />
    );
  } else {
    return null;
  }
};
