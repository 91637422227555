import React, {useEffect, useState} from 'react';

import WhatsApp from '../../assets/icons/WhatsappLogo.svg';
import styles from './whatsapp.module.scss';

const WhatsAppComponent = ({contact = '', message = '', timing = 0}) => {
  const [shouldRender, setShouldRender] = useState(false);
  const encodedMessage = encodeURIComponent(message);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShouldRender(true);
    }, timing * 1000);

    return () => clearTimeout(timeoutId);
  }, [timing]);

  return shouldRender ? (
    <a
      href={`https://api.whatsapp.com/send?phone=${contact}&text=${encodedMessage}`}
      target='_blank'
      rel='noopener noreferrer'
      className={styles.zapContainer}
    >
      <img src={WhatsApp} alt='imagem do Whatsapp' />
    </a>
  ) : null;
};

export default WhatsAppComponent;
