import {formatCEP, formatCPF, getStates, isValidCEP} from '@brazilian-utils/brazilian-utils';
import Accordion from 'components/Accordion';
import ConfirmationPayment from 'components/ConfirmationPayment';
import Input from 'components/Input';
import LoadingInfo from 'components/LoadingInfo';
import Orderbump from 'components/Orderbump';
import Select from 'components/Select';
import Temporizador from 'components/Temporizador';
import WhatsAppComponent from 'components/Whatsapp';
import useDataSubmission from 'hooks/useDataSubmission';
import useHDNParameters from 'hooks/useHDNParameters';
import useSubmission from 'hooks/useSubmission';
import useUTMParameters from 'hooks/useUTMParameters';
import useWidth from 'hooks/useWidth';
import GTM from 'modules/cookies/GTM';
import {MetaPixel} from 'modules/cookies/MetalPixel';
import {getPostalCode} from 'modules/customer/api';
import {CustomerActions} from 'modules/customer/redux';
import CustomerSelectors from 'modules/customer/selectors';
import {PaymentActions} from 'modules/payment/redux';
import PaymentSelectors from 'modules/payment/selectors';
import {ProductActions} from 'modules/product/redux';
import ProductSelectors from 'modules/product/selectors';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useDebouncedCallback} from 'use-debounce';
import {masks, PaymentMethods} from 'utils/constants';
import {convertToCurrency, formatDate, formatPhoneNumber, formValidate} from 'utils/functions';
import {personalDataSchema, validateDataSchemaCNPJ, validateDataSchemaCPF} from 'utils/schemas';
import ErrorTemp from 'views/Fail/components/ErrorTemp';
import GeneratedBoletoPage from 'views/GeneratedBoletoPage';
import PaymentConfirmation from 'views/PaymentConfirmation';
import {useTranslation} from 'react-i18next'; //eslint-disable-line
import PixKeyPage from 'views/PixKeyPage';

import Logo from '../../assets/icons/cispay-logo.svg';
import WarningIcon from '../../assets/icons/Warning.svg';
import Payment from '../../views/Payment';
import styles from './Initial.module.scss';

function Pitch({logo = true, headerClassName, accordionClassName, previewMode = false, personalized = false}) {
  const {t} = useTranslation();
  const [states, setStates] = useState([]);
  const session = useSelector(ProductSelectors.session); //eslint-disable-line
  const customer = useSelector(CustomerSelectors.customer);
  const [loadingPostalCode, setLoadingPostalCode] = useState(false);
  const [formValidation, setFormValidation] = useState({});
  const typeCostumer = useSelector(ProductSelectors.getTypeCustomer);
  const [document, setDocument] = useState('cpf'); //eslint-disable-line
  const [personalData, setPersonalData] = useState(document === 'cpf' ? Mapper.from : MapperCNPJ.from);
  const [validateData, setValidateData] = useState({});
  const {submit} = useDataSubmission();
  const [valid, setValid] = useState(false);
  const product = useSelector(ProductSelectors.product);
  const [data, setData] = useState({});
  const {submitExtra} = useSubmission();
  const statusPayment = useSelector(PaymentSelectors.statusPayment);
  const dispatch = useDispatch();
  const productType = useSelector(ProductSelectors.productType);
  const questions = useSelector(ProductSelectors.questions);
  const errorMessage = useSelector(PaymentSelectors.getErrorMessage);
  const [statusData, setStatusData] = useState(false);
  const [erroEmail, setErroEmail] = useState('');
  const statusPix = useSelector(PaymentSelectors.statusPix);
  const [callLeads, setCallLeads] = useState(false);
  const hdn = useSelector(ProductSelectors.getHdnOrigin);
  const newValueProduct = useSelector(ProductSelectors.getNewValueProduct);
  const quantity = useSelector(ProductSelectors.getQuantity);
  const [formValidated, setFormValidated] = useState(true);
  const [validAmount, setValidAmount] = useState(true);
  const [validOptions, setValidOptions] = useState(false);
  const UTMParameters = useUTMParameters();
  const HDNParameters = useHDNParameters();
  const [statusAmount, setStatusAmount] = useState(false);
  const firstProduct = useSelector(ProductSelectors.firstProduct);
  const selectRef = useRef();
  const timer = useSelector(ProductSelectors.getTimer);
  const [redirect, setRedirect] = useState(false);
  const [redirecting, setRedirecting] = useState(false);
  const loadingProduct = useSelector(ProductSelectors.loading);
  const initialState = true;
  const reducedCheckout = useSelector(ProductSelectors.getReducedCheckout);
  const [conditionalDataAll, setConditionalDataAll] = useState(false);
  const paymentInfo = useSelector(PaymentSelectors.payment);
  const validQuestions = useSelector(PaymentSelectors.getValidQuestions);
  const discountTable = useSelector(ProductSelectors.getDiscountTable);
  const [amount, setAmount] = useState();
  const width = useWidth();
  const responsive = width <= 750;
  const [selectValues, setSelectValues] = useState(Array.from({length: questions?.length}, () => ''));
  const utmParams = useSelector(ProductSelectors.getUtm);
  const [installPlans, setInstallPlans] = useState(0);
  const orderBumpStatus = useSelector(ProductSelectors.orderBumpStatus);
  const paymentPlan = useSelector(ProductSelectors.paymentPlan);
  const disableInputs = useSelector(PaymentSelectors.getDisableInputs);
  const [initialCheck, setInitialCheck] = useState(false);
  const whatsappConfig = useSelector(ProductSelectors.getWhatsappConfig);
  const viewPixKey = useSelector(PaymentSelectors.getViewPixKey);
  const statusBoleto = useSelector(PaymentSelectors.getStatus);
  const viewGeneratedBoletoPage = (paymentInfo?.paymentMethod === PaymentMethods.BOLETO) && statusBoleto === 200;

  const payloadPersonalData = useMemo(() => {
    return {
      doingBusiness: '',
      corporateName: '',
      firstName: '',
      lastName: '',
      gender: '',
      birthDay: '',
      email: '',
      confirmEmail: '',
      street: '',
      phone: '',
      postalCode: '',
      district: '',
      number: '',
      city: '',
      cpf: '',
      cnpj: '',
    };
  }, []);

  useEffect(() => {
    if (typeCostumer === 'natural') {
      setDocument('cpf');
    } else if (typeCostumer === 'company') {
      setDocument('cnpj');
    }
  }, [typeCostumer]);

  const handleChangeExtra = async (e, field, index) => {
    e.preventDefault();
    const toClear = {};

    const updatedValues = selectValues.map((value, idx) => (idx > index ? '' : value));

    updatedValues[index] = e.target.value;
    setSelectValues(updatedValues);

    let newOptions = product?.product?.options; //eslint-disable-line
    Object.keys(data).forEach((option, indexOption) => {
      if (indexOption > index) {
        toClear[option] = '';
        newOptions[indexOption].values = [];
        dispatch(ProductActions.changeOptions(newOptions));
      }
    });

    if (statusPix?.status === 'PAYMENT_PENDING') {
      dispatch(PaymentActions.generatedPix(false));
      dispatch(PaymentActions.changePixExpired(true));
    }
    dispatch(PaymentActions.setOptions(Object.assign({...data, [field]: e.target.value}, toClear)));
    setData(Object.assign({...data, [field]: e.target.value}, toClear));

    const payload = quantity <= 1 ? {...data, ...session, [field]: e.target.value, ...hdn} :
      {...data, ...session, [field]: e.target.value, quantityProducts: quantity, ...hdn};

    await submitExtra(Object.assign(payload, toClear));
  };

  const handleChangeCPF = async e => {
    e.preventDefault();

    const {value} = e.target;

    setValidateData({cpf: value});
    setInitialCheck(true);

    const isFormValid = await handleValidateForm(validateDataSchemaCPF, {cpf: value});

    setFormValidation(isFormValid);

    setValid(isFormValid?.isValid);

    dispatch(PaymentActions.changePixExpired(true));

    questions && selectRef.current.resetState();

    questions && setValidOptions(true);

    if (value === '') {
      setStatusAmount(false);
      setPersonalData(payloadPersonalData);
    }

    if (isFormValid?.isValid) {
      await submit({document: value, quantityProducts: quantity > 0 ? quantity : undefined, type: 'cpf'});
      setStatusAmount(true);
    }
  };

  const handleChangeCNPJ = async e => {
    e.preventDefault();

    const {value} = e.target;

    setValidateData({cnpj: value});

    const isFormValid = await handleValidateForm(validateDataSchemaCNPJ, {cnpj: value});

    setFormValidation(isFormValid);

    setValid(isFormValid?.isValid);

    dispatch(PaymentActions.changePixExpired(true));

    questions && selectRef.current.resetState();

    questions && setValidOptions(true);

    if (value === '') {
      setStatusAmount(false);
      setPersonalData(payloadPersonalData);
    }

    if (isFormValid?.isValid) {
      await submit({document: value, quantityProducts: quantity > 0 ? quantity : undefined});
      setStatusAmount(true);
    }
  };

  const handleValidateForm = async (schema, validateData) => { //eslint-disable-line
    const isFormValid = await formValidate(schema, validateData);
    return isFormValid;
  };

  useEffect(() => {
    if (customer && newValueProduct && validAmount) {
      submitExtra(Object.assign({...data, ...session, quantityProducts: quantity, ...hdn}));
      setValidAmount(false);
    }
  }, [customer, data, newValueProduct, quantity, session, submitExtra, validAmount, hdn]);

  useEffect(() => {
    const cpfToCompare = personalData?.cpf;

    if (customer && document === 'cpf' && cpfToCompare !== customer?.document?.number) {

      setPersonalData({
        firstName: customer?.firstName,
        lastName: customer?.lastName,
        gender: customer?.gender,
        birthDay: formatDate(customer?.birthDay),
        email: customer?.email,
        confirmEmail: customer?.email || '',
        phone: formatPhoneNumber(customer?.phone),
        cpf: customer.document?.number,

        postalCode: formatCEP(customer?.address?.postalCode),
        street: customer?.address?.street,
        number: customer?.address?.number,
        complement: customer?.address?.complement || '',
        district: customer?.address?.district,
        city: customer?.address?.city,
        state: customer?.address?.state,

        country: 'BRA',
      });

    }
  }, [customer]); //eslint-disable-line

  useEffect(() => {
    const cnpjToCompare = personalData?.cnpj?.replace(/[.\s-]+/g, '');

    if (customer && document === 'cnpj' && cnpjToCompare !== customer?.document?.number) {
      setPersonalData({
        corporateName: customer?.corporateName || '',
        doingBusiness: customer?.doingBusiness || '',
        email: customer?.email || '',
        confirmEmail: customer?.email || '',
        phone: formatPhoneNumber(customer?.phone) || '',
        cnpj: validateData.cnpj.replace(/\D/g, '') || '',

        postalCode: formatCEP(customer?.address?.postalCode) || '',
        street: customer?.address?.street || '',
        number: customer?.address?.number || '',
        complement: customer?.address?.complement || '',
        district: customer?.address?.district || '',
        city: customer?.address?.city || '',
        state: customer?.address?.state || '',

        country: 'BRA',
      });

    }
  }, [customer]); //eslint-disable-line

  useEffect(() => {
    handleGetStates(personalData.country);
  }, [personalData.country]);

  useEffect(() => {
    let validStatus = true;
    if (formValidation.errors) {
      const keys = Object.keys(formValidation.errors);
      keys.forEach(key => {
        if (formValidation.errors[key]) {
          validStatus = false;
        }
      });
    }
    setFormValidated(validStatus);
  }, [formValidation]);

  useEffect(() => {
    let timeoutId;

    const handleDelay = () => {
      const validPostalCode = isValidCEP(personalData.postalCode);
      if (validPostalCode && personalData.country) {
        const postalCode = String(personalData.postalCode).replace(/[-]+/g, '');
        handlePostalCode(personalData.country, postalCode);
      }
    };

    if (personalData.postalCode) {
      timeoutId = setTimeout(handleDelay, 300);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [personalData.postalCode]); //eslint-disable-line

  const handleGetStates = async () => {
    try {
      const allStates = await getStates();
      const valuesStatus = allStates?.map(value => ({
        option: value?.code,
        value: value?.code,
      }));

      setStates(valuesStatus);
    } catch (error) {
      setStates([]);
    }
  };

  useEffect(() => {
    let i = 0;
    questions?.forEach((options, index) => { //eslint-disable-line
      if (Object.values(data)[index] === '') {
        ++i;
      }
    });

    if (i > 0) {
      setValidOptions(true);
    } else {
      setValidOptions(false);
    }

    questions?.map((options, index) => { //eslint-disable-line
      if (Object.values(data)[index] === '') {
        setStatusData(true);
      } else {
        setStatusData(false);
      }
    });

  }, [data, questions, statusData]);

  const handleConditionalPersonalData = useCallback(() => {
    const responseCPF = !!personalData.firstName &&
    !!personalData.lastName &&
    !!personalData.gender &&
    !!personalData.email &&
    !!personalData.confirmEmail &&
    (!formValidation?.errors?.birthDay && !!personalData.birthDay) &&
    (personalData?.phone.length >= 14) &&
      personalData.email === personalData.confirmEmail ? true : false;

    const responseCNPJ =
      !!personalData.corporateName &&
      !!personalData.doingBusiness &&
      !!personalData.email &&
      !!personalData.confirmEmail &&
      (personalData?.phone.length >= 14) &&
        personalData.email === personalData.confirmEmail ? true : false;

    return document === 'cpf' ? responseCPF : responseCNPJ;
  }, [personalData, formValidated]); //eslint-disable-line

  const handleConditionalReducedPersonalData = useCallback(() => {
    const responseCPF = !!personalData.firstName &&
    !!personalData.lastName &&
    !!personalData.email &&
    !!personalData.confirmEmail &&
    (!formValidation?.errors?.birthDay && !!personalData.birthDay) &&
    (personalData?.phone.length >= 14) &&
      personalData.email === personalData.confirmEmail ? true : false;

    return responseCPF;
  }, [personalData, formValidated]); //eslint-disable-line

  const handleConditionalAddress = useCallback(() => {
    const responseCPF =
    !!personalData.postalCode &&
    !!personalData.number &&
    !!personalData.district &&
    !!personalData.street &&
    !!personalData.city;

    const responseCNPJ =
      !!personalData.postalCode &&
      !!personalData.number &&
      !!personalData.district &&
      !!personalData.street &&
      !!personalData.city;

    return document === 'cpf' ? responseCPF : responseCNPJ;
  }, [personalData, formValidated]); //eslint-disable-line

  const conditionalData = handleConditionalPersonalData();

  const conditionalAddress = handleConditionalAddress();

  const conditionalReducedData = handleConditionalReducedPersonalData();

  useEffect(() => {
    const calcultedConditionalDataAll = (reducedCheckout && document === 'cpf')
      ? conditionalReducedData
      : (conditionalData && conditionalAddress);

    if (calcultedConditionalDataAll) {
      debounced();
    }

    setConditionalDataAll(calcultedConditionalDataAll);
  }, [reducedCheckout, document, conditionalData, conditionalAddress, personalData, formValidated]); //eslint-disable-line

  const handlePostalCode = async (country, cep) => {
    try {
      setLoadingPostalCode(true);
      const response = await getPostalCode({country, postal_code: cep});

      setPersonalData({
        ...personalData,
        street: response?.address || personalData?.street || '',
        state: response?.state || personalData?.state || '',
        city: response?.city || personalData?.city || '',
        district: response?.district || personalData?.district || '',
      });

      const excludes = ['postalCode', 'street', 'state', 'city', 'district'];
      const auxErrors = {};
      Object.keys(formValidation.errors || []).map(key => {
        if (!excludes.includes(key)) {
          Object.assign(auxErrors, {[key]: formValidation.errors[key]});
        }
        return key;
      });

      setFormValidation({
        ...formValidation,
        errors: auxErrors,
      });
    } catch (error) {
      if (error.response.status === 404) {
        setPersonalData({
          ...personalData,
          street: personalData?.street || '',
          state: personalData?.state || '',
          city: personalData?.city || '',
          district: personalData?.district || '',
        });
      }
    } finally {
      setLoadingPostalCode(false);
    }
  };

  const debounced = useDebouncedCallback(
    () => {
      const payloadCPF = {
        ...session,
        ...Mapper.to(personalData, reducedCheckout),
      };

      const payloadCNPJ = {
        ...session,
        ...MapperCNPJ.to(personalData, reducedCheckout),
      };
      const payloadOptions = {
        ...session,
        ...data,
        ...UTMParameters,
        ...HDNParameters,
      };

      if (formValidated) {
        dispatch(CustomerActions.postLeads(document === 'cpf' ? payloadCPF : payloadCNPJ, statusPix, product, payloadOptions));
      }
    }, 300);

  const handleChange = async e => {
    e.preventDefault();

    const {id, value} = e.target;

    if (personalData[id] !== value) {
      setCallLeads(true);
    } else {
      setCallLeads(false);
    }

    setPersonalData(prevState => ({...prevState, [id]: value}));

    if (value !== '') {
      const isFormValid = await handleValidateForm(personalDataSchema, {[id]: value});

      setFormValidation(
        {
          ...formValidation,
          errors: {
            ...formValidation.errors,
            [id]: isFormValid.errors[id],
          },
        },
      );
    }
  };

  const handdleBlue = async (e) => {
    e.preventDefault();
    const {id, value} = e.target;

    const isFormValid = await handleValidateForm(personalDataSchema, {[id]: value});

    setFormValidation(
      {
        ...formValidation,
        errors: {
          ...formValidation.errors,
          [id]: isFormValid.errors[id],
        },
      },
    );
    if (callLeads) {
      debounced();
      dispatch(PaymentActions.changePixExpired(true));
      setCallLeads(false);
    }
  };

  useEffect(() => {
    if (personalData.email !== personalData.confirmEmail) {
      setErroEmail(t('ERROR_EMAILS'));
    } else {
      setErroEmail('');
    }
  }, [personalData.email, personalData.confirmEmail, t]);

  useEffect(() => {
    if (!valid) {
      setSelectValues([]);
    }
  }, [valid]);

  const onTick = (secondsRemaining) => {
    if (secondsRemaining <= 0 && !redirecting) {
      setRedirecting(true);

      if (timer?.isRedirect?.enabled) {
        const utmParameters = new URLSearchParams(utmParams);
        const utmString = Object.keys(utmParams).length > 0 ? `/${utmParameters.toString()}` : '';
        const finalURL = `${timer?.isRedirect?.link}${utmString}`;
        window.location.replace(finalURL);
      } else {
        setRedirect(true);
      }
    }
  };

  const handleChangeDocument = useCallback((e) => {
    setDocument(e.target.value);
    questions && selectRef.current.resetState();
    dispatch(PaymentActions.changePixExpired(true));
    dispatch(PaymentActions.changeStatusPix(null));
    setValidateData({cpf: ''} || {cnpj: ''});
    setPersonalData(payloadPersonalData);
    setStatusAmount(false);
    setValid(false);
    dispatch(ProductActions.getOrderBumpStatus(false));
  }, [dispatch, questions, payloadPersonalData]);

  const newDataAmount = discountTable?.map((item, idx) => ({option: idx + 1, value: item}));

  const handleAmount = async (e) => {
    setAmount(e.target.value);
    setInstallPlans(e.target.options.selectedIndex);
    dispatch(ProductActions.setQuantity(e.target.options.selectedIndex + 1));
    dispatch(PaymentActions.changePixExpired(true));

    await submitExtra(Object.assign({...data, ...session, quantityProducts: e.target.options.selectedIndex + 1}));
    dispatch(ProductActions.setNewValueProduct(e?.target?.value));
  };

  const handleLastAmount = () => {
    if (firstProduct && Array.isArray(firstProduct.paymentPlan) && firstProduct.paymentPlan.length > 0) {
      const install = product?.product?.credit?.installments?.length;

      if (orderBumpStatus) {
        return product?.product?.credit?.installmentsBump[install - 1]?.installmentAmount;
      } else if (!product?.product?.credit || !product?.product?.boleto || !product?.product?.pix) {
        return firstProduct?.paymentPlan ? firstProduct?.plans[installPlans]?.installmentAmount : paymentPlan[paymentPlan?.length - 1]?.installmentAmount;
      } else {
        return product?.product?.credit?.installments[install - 1]?.installmentAmount;
      }
    }
  };

  if (viewPixKey) {
    return <PixKeyPage logo={logo} personalized={personalized} />;
  }

  if (viewGeneratedBoletoPage) {
    return <GeneratedBoletoPage logo={logo} personalized={personalized} />;
  }

  return (
    <div className={styles.container}>
      {(statusPayment && (paymentInfo?.paymentMethod === PaymentMethods.CREDITCARD || paymentInfo?.paymentMethod === PaymentMethods.PIX)) ? <ConfirmationPayment logo={logo} /> : <div className={`${redirect ? '' : styles.container_all}`}>
        {redirect ? <ErrorTemp logo={logo} /> : <div className={styles.containerMain}>
          {!redirect && <div className={`${styles.header} ${headerClassName ? headerClassName : ''}`}>

            <div className={`${styles.headerImages} ${!logo ? styles.noLogo : ''}`}>
              {logo && <div className={firstProduct?.timing ? styles.logos_timing : styles.logos}>
                <img className={styles.cispay} src={Logo} alt='Logo CISPAY' />
              </div>}

              {firstProduct?.timing && <div className={`${styles.timing} ${personalized ? styles.timingWithPersonalize : ''}`}>
                <Temporizador
                  expiration={timer?.expiration}
                  onTick={onTick}
                  showModal={false}
                />
              </div>}
            </div>

            {!personalized && <span className={styles.titleSale}>{t('TITLE_SALE')}</span>}

            {!product?.product ?
              <div className={styles.headerLoading}>
                <LoadingInfo />
              </div> :
              <div style={{marginTop: discountTable && '20px'}} className={styles.headerInfo}>
                {personalized && <span className={styles.personalizedTitle}>{t('TITLE_SALE')}</span>}

                <div className={styles.headerContent}>
                  {discountTable && <div className={styles.newStyleValue}>
                    <span className={styles.productName}>{product?.product?.name}</span>
                    {discountTable && !responsive ? <strong className={styles.price}><span className={styles.instalHeader}>Em até </span><span className={styles.instalHeader}>{firstProduct?.paymentPlan?.length}x de </span>{convertToCurrency(handleLastAmount())}</strong> : <></>}
                  </div>}

                  {!discountTable && <span className={styles.productName}>{product?.product?.name}</span>}

                  <div className={styles.separatorTab} />
                  <div className={styles.productPrice}>
                    {discountTable ? <>
                      <div className={styles.quantity}>
                        <span className={styles.spanQuant}>Quantidade* </span>
                        <div className={styles.selectAmount}>
                          <Select
                            id='amount'
                            className={styles.paddingLeftSelect}
                            disabled={disableInputs}
                            name='amount'
                            data={newDataAmount}
                            value={amount}
                            error={formValidation?.errors?.gender}
                            onChange={e => handleAmount(e)}
                            salesGroup={true}
                          />
                        </div>
                      </div>
                    </> : <strong className={styles.price}><span className={styles.instalHeader}>Em até {firstProduct?.paymentPlan?.length}x de </span>{convertToCurrency(handleLastAmount())}</strong>}
                  </div>

                  {discountTable && <div className={styles.priceMobile}>
                    <strong><span className={styles.instalHeader}>Em até {firstProduct?.paymentPlan?.length}x de </span> <span className={styles.styleValue}>{convertToCurrency(handleLastAmount())}</span></strong>
                  </div>}
                </div>
              </div>}

          </div>}
          <Accordion initialState={initialState} canOpen={true} messageError={initialCheck && (reducedCheckout && document === 'cpf' ? !conditionalReducedData : !conditionalData)}>
            <div title='Dados do comprador'>
              <div className={styles.card_payment}>
                <div className={styles.main}>
                  {(!productType || valid) && <div className={styles.personalData}>

                    {(!productType || valid) && <div className={styles.cpf}>
                      {<div className={styles.documentContainer}>
                        {typeCostumer === 'both' && <Select
                          id='cnpj'
                          name='cnpj'
                          value={document}
                          title='CPF/CNPJ'
                          data={[
                            {option: t('CPF').toUpperCase(), value: 'cpf'},
                            {option: t('CNPJ').toUpperCase(), value: 'cnpj'},
                          ]}
                          onChange={e => handleChangeDocument(e)}
                        />}
                      </div>}
                      {
                        document === 'cpf' ? <Input
                          title='CPF'
                          placeholder={t('CPF')}
                          mask={masks?.cpfMask}
                          value={formatCPF(validateData.cpf) || ''}
                          disabled={!product?.product}
                          onChange={handleChangeCPF}
                          autoFocus={true}
                          error={formValidation?.errors?.cpf}
                          mandatory={true}
                          withoutOpacity={false}
                        /> : <Input
                          title='CNPJ'
                          placeholder={t('CNPJ')}
                          mask={masks?.cnpjMask}
                          value={validateData.cnpj || ''}
                          disabled={!product?.product}
                          onChange={handleChangeCNPJ}
                          autoFocus={true}
                          error={formValidation?.errors?.cnpj}
                          mandatory={true}
                          withoutOpacity={false}
                        />
                      }
                    </div>}

                    <div style={{marginTop: '10px'}} className={styles.form}>
                      {
                        document === 'cpf' ? <div className={styles.grid}>
                          <Input
                            id='firstName'
                            title='Nome'
                            placeholder={t('FIRST_NAME')}
                            value={personalData?.firstName || ''}
                            disabled={!valid || loadingProduct}
                            onChange={handleChange}
                            error={formValidation?.errors?.firstName}
                            onBlur={e => handdleBlue(e)}
                            mandatory={true}
                            withoutOpacity={!valid}
                          />
                          <Input
                            id='lastName'
                            title='Sobrenome'
                            placeholder={t('LAST_NAME')}
                            value={personalData?.lastName || ''}
                            disabled={!valid || loadingProduct}
                            onChange={handleChange}
                            error={formValidation?.errors?.lastName}
                            onBlur={e => handdleBlue(e)}
                            mandatory={true}
                            withoutOpacity={!valid}
                          />
                        </div> : <div className={styles.grid}>
                          <Input
                            id='corporateName'
                            title='Razão Social'
                            placeholder={t('CORPORATE_NAME')}
                            value={personalData?.corporateName || ''}
                            disabled={!valid || loadingProduct}
                            onChange={handleChange}
                            error={formValidation?.errors?.corporateName}
                            onBlur={e => handdleBlue(e)}
                            mandatory={true}
                            withoutOpacity={!valid}
                          />
                          <Input
                            id='doingBusiness'
                            title='Nome Fantasia'
                            placeholder={t('DOING_BUSINESS')}
                            value={personalData?.doingBusiness || ''}
                            disabled={!valid || loadingProduct}
                            onChange={handleChange}
                            error={formValidation?.errors?.doingBusiness}
                            onBlur={e => handdleBlue(e)}
                            mandatory={true}
                            withoutOpacity={!valid}
                          />
                        </div>
                      }

                      {
                        document === 'cpf' && !reducedCheckout && <div className={styles.grid}>
                          <Select
                            id='gender'
                            name='personalData.gender'
                            title={t('GENDER')}
                            placeholder={t('GENDER')}
                            message='Sexo'
                            data={[{option: t('MALE'), value: 'M'}, {option: t('FEMALE'), value: 'F'}]}
                            value={personalData?.gender || ''}
                            error={formValidation?.errors?.gender}
                            onChange={handleChange}
                            disabled={loadingPostalCode || (!valid || loadingProduct)}
                            onBlur={e => handdleBlue(e)}
                            mandatory={true}
                            spacingTop={true}
                            withoutOpacity={!valid}
                            statusColor={!valid}
                          />
                          <Input
                            id='birthDay'
                            title='Data de Nascimento'
                            placeholder={t('BIRTHDAY')}
                            type='text'
                            guide={true}
                            mask={masks?.dateBirthDayMask}
                            value={personalData?.birthDay || ''}
                            disabled={!valid || loadingProduct}
                            onChange={handleChange}
                            error={formValidation?.errors?.birthDay}
                            onBlur={e => handdleBlue(e)}
                            mandatory={true}
                            withoutOpacity={!valid}
                          />
                        </div>
                      }

                      <div className={styles.grid}>
                        <Input
                          type='email'
                          id='email'
                          name='personalData.email'
                          title={t('EMAIL')}
                          placeholder={t('EMAIL')}
                          value={personalData?.email || ''}
                          onChange={handleChange}
                          disabled={!valid || loadingProduct}
                          error={formValidation?.errors?.email}
                          onBlur={e => handdleBlue(e)}
                          mandatory={true}
                          withoutOpacity={!valid}
                        />
                        <Input
                          type='email'
                          id='confirmEmail'
                          name='personalData.confirmEmail'
                          title={t('EMAIL_CONFIRMATION')}
                          placeholder={t('EMAIL_CONFIRMATION')}
                          value={personalData?.confirmEmail || ''}
                          onChange={handleChange}
                          onBlur={e => handdleBlue(e)}
                          disabled={!valid || loadingProduct}
                          error={erroEmail}
                          mandatory={true}
                          withoutOpacity={!valid}
                        />
                      </div>

                      <div className={styles.grid}>
                        {reducedCheckout && document === 'cpf' && <Input
                          id='birthDay'
                          title='Data de Nascimento'
                          placeholder={t('BIRTHDAY')}
                          type='text'
                          guide={true}
                          mask={masks?.dateBirthDayMask}
                          value={personalData?.birthDay || ''}
                          disabled={!valid || loadingProduct}
                          onChange={handleChange}
                          error={formValidation?.errors?.birthDay}
                          onBlur={e => handdleBlue(e)}
                          mandatory={true}
                          withoutOpacity={!valid}
                        />}
                        <Input
                          id='phone'
                          name='personalData.phone'
                          title={t('PHONE')}
                          placeholder={t('PHONE')}
                          mask={masks?.cellPhoneMask}
                          value={personalData?.phone || ''}
                          disabled={!valid || loadingProduct}
                          onChange={handleChange}
                          onBlur={e => handdleBlue(e)}
                          error={formValidation?.errors?.phone}
                          mandatory={true}
                          withoutOpacity={!valid}
                        />
                      </div>
                    </div>

                    {((reducedCheckout && document === 'cpf' ? !conditionalReducedData : !conditionalData)) && <div className={styles.paymentMessage}>
                      <img src={WarningIcon} alt='icone' />
                      <span>{t('MESSAGE_ERROR')}</span>
                    </div>}

                  </div>}
                </div>
              </div>
            </div>
          </Accordion>

          {(reducedCheckout && document === 'cpf') ? <></> : <Accordion canOpen={false} messageError={initialCheck && !conditionalAddress}>
            <div title='Endereço'>
              <div style={{marginTop: '20px', padding: '0px 10px'}}>
                <Input
                  id='postalCode'
                  name='personalData.postalCode'
                  title={t('POSTAL_CODE')}
                  placeholder={t('POSTAL_CODE')}
                  mask={masks?.postalCodeMask}
                  value={personalData?.postalCode || ''}
                  disabled={!valid || loadingProduct}
                  onChange={handleChange}
                  onBlur={e => handdleBlue(e)}
                  error={formValidation?.errors?.postalCode}
                  mandatory={true}
                  withoutOpacity={!valid}
                />

                <div className={styles.grid}>
                  <Input
                    id='street'
                    name='personalData.street'
                    title={t('ADDRESS')}
                    placeholder={t('ADDRESS')}
                    value={personalData?.street || ''}
                    onChange={handleChange}
                    disabled={loadingPostalCode || (!valid || loadingProduct)}
                    onBlur={e => handdleBlue(e)}
                    error={formValidation?.errors?.street}
                    mandatory={true}
                    withoutOpacity={!valid}
                  />
                  <Input
                    id='number'
                    name='personalData.number'
                    title={t('NUMBER')}
                    placeholder={t('NUMBER')}
                    mask={masks?.houseNumber}
                    value={personalData?.number || ''}
                    onChange={handleChange}
                    onBlur={e => handdleBlue(e)}
                    disabled={loadingPostalCode || (!valid || loadingProduct)}
                    error={formValidation?.errors?.number}
                    mandatory={true}
                    withoutOpacity={!valid}
                  />
                </div>

                <div className={styles.grid}>
                  <Input
                    id='complement'
                    name='personalData.complement'
                    title={t('COMPLEMENT')}
                    placeholder={t('COMPLEMENT')}
                    optional={true}
                    disabled={loadingPostalCode || (!valid || loadingProduct)}
                    value={personalData?.complement || ''}
                    onChange={handleChange}
                    error={formValidation?.errors?.complement}
                    withoutOpacity={!valid}
                    onBlur={e => handdleBlue(e)}
                  />
                  <Input
                    id='district'
                    name='personalData.district'
                    title={t('DISTRICT')}
                    placeholder={t('DISTRICT')}
                    disabled={loadingPostalCode || (!valid || loadingProduct)}
                    value={personalData?.district || ''}
                    onChange={handleChange}
                    error={formValidation?.errors?.district}
                    onBlur={e => handdleBlue(e)}
                    mandatory={true}
                    withoutOpacity={!valid}
                  />
                </div>

                <div className={styles.grid}>
                  <div className={styles.state}>
                    <Select
                      id='state'
                      name='personalData.state'
                      title={t('STATE')}
                      placeholder={t('STATE')}
                      message='Selecione o seu estado'
                      data={states}
                      value={personalData?.state || ''}
                      error={formValidation?.errors?.state}
                      onChange={handleChange}
                      disabled={loadingPostalCode || (!valid || loadingProduct)}
                      onBlur={e => handdleBlue(e)}
                      mandatory={true}
                      spacingTop={true}
                      withoutOpacity={!valid}
                      statusColor={loadingPostalCode || !valid}
                    />
                  </div>
                  <Input
                    id='city'
                    title={t('CITY')}
                    placeholder={t('CITY')}
                    disabled={loadingPostalCode || (!valid || loadingProduct)}
                    value={personalData?.city || ''}
                    onChange={handleChange}
                    error={formValidation?.errors?.city}
                    onBlur={e => handdleBlue(e)}
                    mandatory={true}
                    withoutOpacity={!valid}
                  />
                </div>
              </div>
            </div>
          </Accordion>}

          {firstProduct?.questions && <Accordion canOpen={false} messageError={initialCheck && (validOptions || (questions && validQuestions))}>
            <div title='Opções' style={{margin: '-5px 0px 15px'}}>
              <div className={styles.form_wrapper}>
                {questions?.map((option, index) => {
                  return (
                    <div key={index}>
                      <Select
                        key={index}
                        id={option}
                        name={option}
                        title={option}
                        ref={selectRef}
                        clearOnUmount
                        message={option}
                        error={Object.values(data)[index] === '' && errorMessage}
                        statusColor={reducedCheckout ? !conditionalReducedData : !conditionalDataAll}
                        disabled={disableInputs ? disableInputs : reducedCheckout ? !conditionalReducedData : !conditionalDataAll}
                        data={product?.product?.options?.[index]?.values?.map(({key, label}) => ({option: label, value: key}))}
                        value={selectValues[index]}
                        withoutOpacity={!valid}
                        onChange={e => handleChangeExtra(e, product?.product?.options?.[index].key, index)}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </Accordion>}
          {(reducedCheckout ? conditionalReducedData : conditionalData) && product?.orderbump && <Orderbump />}

          <Accordion initialState={false} canOpen={false} messageError={false} footer={true} className={`${accordionClassName ? accordionClassName : ''}`}>
            {statusPayment ?
              <div>
                <PaymentConfirmation />
              </div>
              :
              <div title='Pagamento' className={styles.payment}>
                {discountTable && <>
                  <div className={styles.quantityWrap}>
                    <span className={styles.spanQuant}>Quantidade* </span>
                    <div className={styles.selectAmount}>
                      <Select
                        id='amount'
                        className={styles.paddingLeftSelect}
                        disabled={!conditionalDataAll || validOptions || (questions && validQuestions)}
                        name='amount'
                        data={newDataAmount}
                        value={amount}
                        onChange={e => handleAmount(e)}
                        inputWidth='100%'
                        salesGroup={true}
                      />
                    </div>
                  </div>
                </>}
                {product && <Payment
                  validate={!conditionalDataAll || validOptions || (questions && validQuestions)}
                  statusAmount={statusAmount}
                  statusData={statusData}
                  personalData={personalData}
                  formValidation={formValidation}
                  previewMode={previewMode}
                />}
              </div>}
          </Accordion>
          {whatsappConfig?.enabled && <WhatsAppComponent contact={whatsappConfig?.contact} message={whatsappConfig?.message} timing={whatsappConfig?.timeShowUp} />}
        </div>}
      </div>}
      {valid && <MetaPixel metaPixel={product?.metaPixel} pixelEvent='Lead'
        contentName={firstProduct?.product?.name}
        currency='BRL' value={firstProduct?.product?.price}
      />}
      <MetaPixel metaPixel={product?.metaPixel} pixelEvent='PageView' />

      {product?.metaGTM && valid && <GTM gtagId={product?.metaGTM} pixelEvent='Lead' valueTotal={firstProduct?.product?.price} />}
      {product?.metaGTM && <GTM gtagId={product?.metaGTM} pixelEvent='PageView' />}
    </div>
  );
}

export default Pitch;

const Mapper = {
  from: () => ({
    firstName: '',
    lastName: '',
    gender: '',
    birthDay: '',
    email: '',
    confirmEmail: '',
    phone: '',
    cpf: '',

    postalCode: '',
    street: '',
    number: '',
    complement: '',
    district: '',
    city: '',
    state: '',

    country: 'BRA',
  }),
  to: (personalData, reducedCustomer) => {
    const customer = {
      firstName: personalData?.firstName,
      lastName: personalData?.lastName,
      email: personalData?.email.trim()?.toLowerCase(),
      birthDay: personalData?.birthDay?.split('/')?.reverse()?.join('-'),
      phone: personalData?.phone?.replace(/[_().\s-]+/g, ''),
      document: {
        type: 'CPF',
        number: personalData?.cpf?.replace(/[.\s-]+/g, ''),
      },
    };

    if (reducedCustomer) {
      customer.reduced = true;
    }

    if (!reducedCustomer) {
      customer.gender = personalData?.gender;
      customer.birthDay = personalData?.birthDay?.split('/')?.reverse()?.join('-');
      customer.address = {
        postalCode: personalData.postalCode.replace(/[-]+/g, ''),
        street: personalData.street,
        number: personalData.number,
        complement: personalData.complement,
        district: personalData.district,
        city: personalData.city,
        state: personalData.state,
        country: 'Brazil',
      };
    }

    return {customer};
  },
};

const MapperCNPJ = {
  from: () => ({
    corporateName: '',
    doingBusiness: '',
    email: '',
    confirmEmail: '',
    phone: '',
    cnpj: '',

    postalCode: '',
    street: '',
    number: '',
    complement: '',
    district: '',
    city: '',
    state: '',

    country: 'BRA',
  }),
  to: (personalData) => ({
    customer: {
      corporateName: personalData?.corporateName,
      doingBusiness: personalData?.doingBusiness,
      email: personalData?.email.trim().toLowerCase(),
      phone: personalData?.phone?.replace(/[_().\s-]+/g, ''),
      document: {
        type: 'CNPJ',
        number: personalData?.cnpj?.replace(/[.\s-]+/g, ''),
      },
      address: {
        postalCode: personalData.postalCode.replace(/[-]+/g, ''),
        street: personalData.street,
        number: personalData.number,
        complement: personalData.complement,
        district: personalData.district,
        city: personalData.city,
        state: personalData.state,
        country: 'Brazil',
      },
    },
  }),
};
