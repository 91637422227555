import {ConnectedRouter} from 'connected-react-router';
import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import App from './App';
import Store, {browserHistory} from './store/store.config';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={Store}>
      <ConnectedRouter history={browserHistory}>
        <App />
        <ToastContainer icon={false} />
      </ConnectedRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);
