import {PaymentActions} from 'modules/payment/redux';
import ProductSelectors from 'modules/product/selectors';
import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {PaymentMethods} from 'utils/constants';
import {debugCode, debugCodeInFinally} from 'utils/debugCode';

function useCreditCardSubmission() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const utmParams = useSelector(ProductSelectors.getUtm);

  const submit = async (payload, checkedTwoCards, status, firstProduct) => { //eslint-disable-line
    dispatch(PaymentActions.setDebugCode(debugCode.C021.code));
    setLoading(true);
    try {
      dispatch(PaymentActions.setDebugCode(debugCode.C004.code));
      await dispatch(PaymentActions.changePaymentMethod(PaymentMethods.CREDITCARD));
      dispatch(PaymentActions.setDebugCode(debugCode.C005.code));
      await dispatch(PaymentActions.submitCreditCard(payload, checkedTwoCards, status, firstProduct, utmParams));
      dispatch(PaymentActions.setDebugCode(debugCode.C022.code));
    } finally {
      setLoading(false);
      dispatch(PaymentActions.setDebugCodeInFinally(debugCodeInFinally.F306.code));
    }
  };

  const changeBackForPaymentBoleto = () => {
    dispatch(PaymentActions.setDebugCode(debugCode.C007.code));
    dispatch(PaymentActions.changeBackForPaymentBoleto(false));
    dispatch(PaymentActions.setDebugCode(debugCode.C023.code));
  };

  return {loading, submit, changeBackForPaymentBoleto};
}

export default useCreditCardSubmission;
