import Time from 'assets/icons/Timer.svg';
import React, {useEffect, useState} from 'react';

import styles from './styles.module.scss';

export default function Temporizador({expiration, onTick}) {
  const [elapsedTime, setElapsedTime] = useState({minutes: 0, seconds: 0});
  const {minutes, seconds} = elapsedTime;

  useEffect(() => {
    let remainingSeconds = expiration;
    const startTime = performance.now();

    const intervalId = setInterval(() => {
      const duration = performance.now() - startTime;

      remainingSeconds = expiration - Math.floor(duration / 1000);

      const minutesRemaining = Math.floor(remainingSeconds / 60);
      const secondsRemaining = remainingSeconds % 60;

      setElapsedTime({minutes: minutesRemaining, seconds: secondsRemaining});
      onTick(remainingSeconds);

      if (remainingSeconds <= 0) {
        clearInterval(intervalId);
      }
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };

  }, [expiration, onTick]);

  return (
    <div className={styles.footer}>
      <secction className={styles.time}>
        <img src={Time} alt='clock logo' />
      </secction>
      <secction className={styles.time}>{minutes > 9 ? minutes : `0${minutes || 0}`}</secction>
      <secction className={styles.time}>{seconds > 9 ? seconds : `0${seconds || 0}`}</secction>
    </div>
  );
}
