import Axios from 'axios';

const baseHeaders = (othersHeaders = {}) => ({
  headers: {
    'Content-Type': 'application/json',
    ...othersHeaders,
  },
});

export const Get = (url, params, headers) => {
  return Axios.get(url, {params, ...baseHeaders(headers)})
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
};

export const Post = (url, data, headers) => {
  return Axios.post(url, data, baseHeaders(headers))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
};

export const Put = (url, data, headers) => {
  return Axios.put(url, data, baseHeaders(headers))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
};

export const Delete = (url, data, headers) => {
  return Axios.delete(url, {data, headers: baseHeaders(headers).headers})
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
};

export const Patch = (url, data, headers) => {
  return Axios.patch(url, data, baseHeaders(headers))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
};
