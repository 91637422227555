import {all} from 'redux-saga/effects';

import {saga as Customer} from '../../modules/customer/redux';
import {saga as Product} from '../../modules/product/redux';

export default function* RootSaga() {
  yield all([
    Product(),
    Customer(),
  ]);
}
