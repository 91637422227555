import React from 'react';
import {useSelector} from 'react-redux';

import Steps from '../Steps';
import styles from './Header.module.scss';

function Header() {
  const {step} = useSelector(({Product}) => Product);

  return (
    <header>
      <div className={styles.content_down}>
        <nav>
          <Steps step={step} />
        </nav>
      </div>
    </header>
  );
}

export default Header;
