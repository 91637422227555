export const CustomerMapperCNPJ = {
  get: (customer) => ({
    ...customer,
    address: {
      ...customer?.address,
      postalCode: customer?.address?.postalCode || '',
    },
    email: customer?.email || '',
    corporateName: customer?.corporateName || '',
    doingBusiness: customer?.doingBusiness || '',
    phone: customer?.phone || '',
  }),
};
