import {handleHeaders} from 'utils/functions';

import {Get, Post} from '../../utils/apiBase';
import {urls} from '../../utils/constants';

const headers = handleHeaders();

export const postLeads = (payload) => {
  return Post(urls.LEADS, payload, headers);
};

export const getStates = ({country}) => {
  return Get(urls.STATES.replace(':country', country));
};

export const getPostalCode = ({country, postal_code: cep}) => {
  return Get(urls.POSTAL_CODE.replace(':country', country), {cep});
};
