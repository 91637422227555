import React, {useEffect} from 'react';
import {Helmet} from 'react-helmet';

export const GTM = ({gtagId, pixelEvent, valueTotal, items, orderId, customerClient, paymentType}) => {
  const urlParams = new URLSearchParams(window.location.search);
  const cookies = urlParams.get('cookies');

  function gtag() {
    window.dataLayer.push(arguments);
  }
  useEffect(() => {
    if (!gtagId || cookies === 'refuse') {
      return null;
    }

    if (!window.gtag) {
      window.dataLayer = window.dataLayer || [];
      gtag('js', new Date());
      gtag('config', gtagId);
      window.gtag = gtag;
    }

    if (pixelEvent === 'Purchase') {
      window.gtag('event', 'purchase', {
        transaction_id: orderId,
        value: valueTotal / 100,
        paymentType: paymentType,
        currency: 'BRL',
        items: items.map((item) => ({
          item_id: item.sector,
          item_name: item.sector_name,
          price: item.price / 100,
          quantity: item.quantity,
        })),
        customer_email: customerClient.email,
        customer_name: customerClient.name,
        customer_phone: customerClient.phone,
        ...(customerClient.birthDay ? {customer_birthday: customerClient.birthDay} : undefined),
      });
    }

    if (pixelEvent === 'Lead') {
      window.gtag('event', 'lead', {
        currency: 'BRL',
        value: valueTotal / 100,
      });
    }
  }, [gtagId, pixelEvent]); //eslint-disable-line

  if (!gtagId || cookies === 'refuse') {
    return null;
  }

  return (
    <Helmet>
      <script>{`
        const f = document.getElementsByTagName('script')[0];
        const j = document.createElement('script');
        j.async = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=${gtagId}';
        f.parentNode.insertBefore(j, f);
      `}</script>
    </Helmet>
  );
};

export default GTM;
