import IconCheck from 'assets/images/CheckCircle.svg';
import PaymentSelectors from 'modules/payment/selectors';
import ProductSelectors from 'modules/product/selectors';
import React from 'react';
import {useSelector} from 'react-redux';
import {convertToCurrency} from 'utils/functions';

import styles from './styles.module.scss';

const PageConfirmation = ({firstProduct, title, overview = false, footer1, footer2, messagePix, typeBoleto = false, overviewIncomplet = false}) => {
  const salesInterest = useSelector(ProductSelectors.getSalesInterest);
  const orderBumpStatus = useSelector(ProductSelectors.orderBumpStatus);
  const firstUpsell = useSelector(PaymentSelectors.getUpsellFirst);
  const hasUpsell = useSelector(PaymentSelectors.getHasUpsell);
  const product = useSelector(ProductSelectors.product);

  const handleSalesInterest = () => {
    if (salesInterest && firstProduct?.credit?.installments > 1) {
      if (firstProduct?.options?.quantityProducts > 1) {
        return firstProduct?.credit?.card?.amount !== firstProduct?.product?.discountTable[firstProduct?.options?.quantityProducts - 1];
      } else {
        return firstProduct?.credit?.card?.amount !== firstProduct?.product?.price;
      }
    }
    return false;
  };

  const handlePrice = () => {
    if (firstProduct?.credit?.card?.amount) {
      return firstProduct?.credit?.card?.amount;
    } else if (firstProduct?.credit?.cards) {
      return firstProduct?.credit?.cards[0].amount + firstProduct?.credit?.cards[1].amount;
    } else if (hasUpsell) {
      return firstUpsell?.upsell?.price;
    } else if (orderBumpStatus) {
      return product?.orderbump?.amount;
    } else if (product?.product?.price) {
      return product?.product?.price;
    } else {
      return firstProduct?.product?.price;
    }
  };

  return (
    <div style={{height: !overview && !overviewIncomplet && '200px'}} className={styles.container_payment}>
      <img src={IconCheck} alt='icone check' className={styles.iconeCheck} />

      <div className={styles.content_payment}>
        <h3 className={styles.title}>{title}</h3>
        {overview && <p>{firstProduct?.product?.name} no valor de {convertToCurrency(handlePrice())} <br />parcelado em {firstProduct?.credit?.installments}x {handleSalesInterest() ? 'com juros' : 'sem juros'}.</p>}
        {overviewIncomplet && <p className={styles.overviewIncomplet}>{firstProduct?.product?.name} no valor de {convertToCurrency(handlePrice())}.</p>}
        <div className={styles.footer_content}>
          {footer1 && <span className={styles.footer1}>{footer1}</span>}
          {footer2 && typeBoleto ?
            <a className={styles.footer2} href={footer2} target='_blank' without='true' rel='noopener noreferrer'>{footer2}</a> :
            <span className={styles.footer2}>{footer2}</span>}
          {messagePix && <span className={styles.messagePix}>{messagePix}</span>}
        </div>
      </div>
    </div>
  );
};

export default PageConfirmation;
