import axios from 'axios';
import Button from 'components/Button';
import Countdown from 'components/Countdown'; //eslint-disable-line
import Tooltip from 'components/Tooltip';
import TryAgain from 'components/TryAgain';
import useHDNParameters from 'hooks/useHDNParameters';
import usePixSubmission from 'hooks/usePixSubmission';
import useUTMParameters from 'hooks/useUTMParameters';
import useWidth from 'hooks/useWidth';
import CustomerSelectors from 'modules/customer/selectors';
import {PaymentActions} from 'modules/payment/redux';
import PaymentSelectors from 'modules/payment/selectors';
import ProductSelectors from 'modules/product/selectors';
import moment from 'moment'; //eslint-disable-line
import React, {useCallback, useEffect, useState} from 'react';
import ContentLoader from 'react-content-loader';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {obterTokenRecaptcha} from 'utils/functions';
import notification from 'utils/notification';

import Refresh from '../../../../assets/images/refresh.png'; //eslint-disable-line

import CopyButtom from '../../../../assets/images/Copy.svg'; //eslint-disable-line
import styles from './pix.module.scss';

function Pix({acceptUpsellEvent}) {
  const {t} = useTranslation();

  const {loading, submit, setLoading} = usePixSubmission();
  const statusPix = useSelector(PaymentSelectors.statusPix);
  const productInfo = useSelector(ProductSelectors.product);
  const session = useSelector(ProductSelectors.session);
  const paymentPix = useSelector(PaymentSelectors.getPaymentPix);
  const pixExpired = useSelector(PaymentSelectors.pixExpired);
  const dispatch = useDispatch();
  const urlMakePayment = paymentPix?.pix?.dev?.url_make_payment;
  const generatedPix = useSelector(PaymentSelectors.generatedPix);
  const oderBumpStatus = useSelector(ProductSelectors?.orderBumpStatus);
  const loadingPix = useSelector(PaymentSelectors.getLoadingPix);
  const errorPix = useSelector(PaymentSelectors.getErrorPix);
  const customer = useSelector(CustomerSelectors.customer);
  const options = useSelector(PaymentSelectors.getOptions);
  const quantity = useSelector(ProductSelectors.getQuantity);
  const [copied, setCopied] = useState(false);
  const UTMParameters = useUTMParameters();
  const HDNParameters = useHDNParameters();
  const width = useWidth();
  const upsell = useSelector(PaymentSelectors.getUpsell);
  const validBump = useSelector(ProductSelectors.getValidBump);

  const hasUpsell = !!upsell?.upsell;

  const handleGeneratePix = async () => {
    const token = await obterTokenRecaptcha();
    const payload = {...session, bumpEnable: oderBumpStatus, customer, token, options: {...options, ...UTMParameters, ...HDNParameters, quantityProducts: quantity > 0 ? quantity : undefined}};
    dispatch(PaymentActions.generatedPix(true));
    dispatch(PaymentActions.changePixExpired(false));
    submit(payload, hasUpsell);
  };

  const handleConfirmationPayment = async () => {
    try {
      await axios.post(urlMakePayment);
      acceptUpsellEvent && dispatch(PaymentActions.setUpsellEvent(true));
    } catch (err) {
      console.log(err); //eslint-disable-line
    }
  };

  useEffect(() => {
    if (loading === false && pixExpired === false) {
      const checkPixStatus = setInterval(() => {
        const payload = {...session, customer, options};
        dispatch(PaymentActions.submitStatusPix(payload, hasUpsell));
      }, 20000);

      return () => {
        clearInterval(checkPixStatus);
      };
    }
  }, [dispatch, loading, pixExpired, session, generatedPix, options, customer, hasUpsell]);

  const onRejected = () => {
    document.location.reload(true);
  };

  const onTick = (minutes, seconds) => { //eslint-disable-line
    if (minutes <= 0 && seconds <= 0) {
      dispatch(PaymentActions.generatedPix(false));
      dispatch(PaymentActions.changePixExpired(true));
    }
  };

  const copyText = useCallback(async (text) => {
    if (!navigator.clipboard) {
      return;
    }

    if (!copied) {
      await navigator.clipboard.writeText(text);
      setCopied(true);
      notification('success', 'código PIX copiado com sucesso!');
      setTimeout(() => setCopied(false), 2000);
    }

  }, [copied]);

  useEffect(() => {
    if (statusPix?.status === 'PAYMENT_APPROVED') {
      dispatch(PaymentActions.setViewPixKey(false));
      dispatch(PaymentActions.pixApproved());
      dispatch(PaymentActions.changeStatusPayment(true));
    }
  }, [statusPix]); //eslint-disable-line

  useEffect(() => {
    if (generatedPix) {
      setLoading(false);
      return;
    }
    handleGeneratePix();
  }, [validBump]); // eslint-disable-line

  return (
    <div className={styles.container_pix}>
      {loading || loadingPix
        ? <Loading />
        : (
          errorPix
            ? <TryAgain title={t('TRY_AGAIN_TITLE')} subtitle={t('TRY_AGAIN_DESC')} onClick={() => handleGeneratePix()} />
            : (
              <>
                <div className={styles.container_first}>
                  <p className={styles.title}>{t('OPEN_QRCODE_SCANNER')}</p>
                  <div className={styles.borderImg}>
                    {pixExpired ?
                      <div className={styles.qrcode} alt='PIX QRCODE'>
                        <img className={styles.qrcode_expired} alt='PIX QRCODE' src={paymentPix?.pix?.qrcode} />
                        <div onClick={() => handleGeneratePix()} className={styles.circle}>
                          <img className={styles.icon} src={Refresh} alt='imageRefresh' />
                          <span className={styles.message_circle}>Seu pix expirou, clique aqui para gerar outro qrcode.</span>
                        </div>
                      </div> :
                      <img className={styles.qrcode_origin} alt='PIX QRCODE' src={paymentPix?.pix?.qrcode} />}
                  </div>
                </div>

                {pixExpired ? '' : <div className={styles.link_qr_code}>
                  <p>{t('COPY_CODE_DESCRIPTION')}</p>

                  <div className={styles.message}>
                    <div className={styles.text_copy}>
                      <span>{paymentPix?.pix?.emv ? paymentPix?.pix?.emv : t('QR_CODE_LOADING_CODE')}</span>
                    </div>
                    {loading === false && <button
                      type='button'
                      onClick={() => copyText(paymentPix?.pix?.emv)}
                      className={styles.copy_button}
                      data-tip data-for='copyTip'
                    >
                      <img src={CopyButtom} alt='COPY BUTTOM' />
                      {width <= 750 && <span className={styles.copyName}>Copiar</span>}
                    </button>}
                    {loading === false && <Tooltip place='top' text={t('COPYPRODUCTLINK')} id='copyTip' />}
                  </div>
                </div>}

                {loading === false && productInfo?.product?.pix?.secondsToExpire && <Countdown
                  expirationDate={moment(paymentPix?.pix?.expirationDate)}
                  onAccepted={handleGeneratePix}
                  onRejected={onRejected}
                  onTick={onTick}
                  showModal={pixExpired}
                />}

                {loading === false && urlMakePayment && <CopyToClipboard>
                  <Button onClick={() => handleConfirmationPayment()}>
                    {t('PAYMENT_CONFIRME')}
                  </Button>
                </CopyToClipboard>}
              </>
            )
        )}
    </div>
  );
}

export default Pix;

const Loading = (props) => {
  return (
    <ContentLoader
      speed={2}
      width={'100%'}
      viewBox={'0 0 800 310'}
      backgroundColor='#e6e6e6'
      foregroundColor='#d8d8d8'
      {...props}
    >
      <rect x='250' y='5' rx='0' ry='0' width='300' height='15' />
      <rect x='340' y='35' rx='0' ry='0' width='120' height='120' />
      <rect x='150' y='190' rx='0' ry='0' width='500' height='15' />
      <rect x='100' y='220' rx='0' ry='0' width='600' height='40' />
      <rect x='290' y='275' rx='0' ry='0' width='220' height='40' />
    </ContentLoader>
  );
};
