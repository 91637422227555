import Button from 'components/Button';
import useHDNParameters from 'hooks/useHDNParameters';
import useIsMounted from 'hooks/useIsMounted';
import useUTMParameters from 'hooks/useUTMParameters';
import CustomerSelectors from 'modules/customer/selectors';
import {PaymentActions} from 'modules/payment/redux';
import PaymentSelectors from 'modules/payment/selectors';
import {ProductActions} from 'modules/product/redux';
import ProductSelectors from 'modules/product/selectors';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {PaymentMethods} from 'utils/constants';
import {obterTokenRecaptcha} from 'utils/functions';

import PaymentDetails from '../PaymentDetails';
import styles from './boleto.module.scss';

function Boleto({statusData, validate}) {
  const {t} = useTranslation();
  const {loading, submit} = useBoletoSubmission();
  const orderBumpStatus = useSelector(ProductSelectors.orderBumpStatus);
  const session = useSelector(ProductSelectors.session);
  const customer = useSelector(CustomerSelectors.customer);
  const options = useSelector(PaymentSelectors.getOptions);
  const quantity = useSelector(ProductSelectors.getQuantity);
  const UTMParameters = useUTMParameters();
  const HDNParameters = useHDNParameters();

  const handleGenerateBoleto = async () => {
    const token = await obterTokenRecaptcha();
    const payload = {...session, bumpEnable: orderBumpStatus, customer, options: {...options, ...UTMParameters, ...HDNParameters, quantityProducts: quantity > 0 ? quantity : undefined}, token};
    submit(payload);
  };

  return (
    <div className={styles.container_boleto}>
      <PaymentDetails title={'OF_BOLETO'} />

      <div className={styles.footer_wrapper}>
        <Button disabled={statusData || validate} loading={loading} type='submit' onClick={() => handleGenerateBoleto()}>{t('GENERATE_BOLETO')}</Button>
      </div>
    </div>
  );
}

export default Boleto;

function useBoletoSubmission() {
  const dispatch = useDispatch();
  const isMounted = useIsMounted();
  const [loading, setLoading] = useState(false);
  const firstProduct = useSelector(ProductSelectors.firstProduct);
  const utmParams = useSelector(ProductSelectors.getUtm);

  const submit = async (payload) => {
    try {
      setLoading(true);
      await dispatch(PaymentActions.changePaymentMethod(PaymentMethods.BOLETO));
      await dispatch(PaymentActions.submitBoleto(payload, firstProduct, utmParams));
    } finally {
      isMounted && setLoading(false);
      dispatch(PaymentActions.changeStatusPayment(true));
      dispatch(ProductActions.setStatusSession(true));
    }
  };

  return {loading, submit};
}
