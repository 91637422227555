export const CustomerMapper = {
  get: (customer) => ({
    ...customer,
    address: {
      ...customer?.address,
      postalCode: customer?.address?.postalCode || '',
    },
    email: customer?.email || '',
    firstName: customer?.firstName || '',
    lastName: customer?.lastName || '',
    phone: customer?.phone || '',
    gender: customer?.gender || '',
    birthDay: customer?.birthDay || '',
  }),
};
