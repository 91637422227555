import React, {useEffect} from 'react';
import PaymentConfirmation from 'views/PaymentConfirmation';

import Logo from '../../assets/icons/cispay-logo.svg';
import styles from './generatedBoletoPage.module.scss';

export default function GeneratedBoletoPage({logo = true, personalized}) {

  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }, []);

  return (
    <div className={`${styles.container} ${personalized ? styles.personalized : ''}`}>
      {logo && <img className={styles.logo} src={Logo} alt='Logo Cispay' />}
      <section className={styles.content}>
        <PaymentConfirmation />
      </section>
    </div>
  );
}
