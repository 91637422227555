import Button from 'components/Button';
import {PaymentActions} from 'modules/payment/redux';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';

import PaymentDetails from '../PaymentDetails';
import styles from './pix-details.module.scss';

export default function PixDetails({validate}) {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  return (
    <div className={styles.containerBoleto}>
      <PaymentDetails title={'OF_PIX'} />
      <div className={styles.footerWrapper}>
        <Button
          disabled={validate}
          type='submit'
          onClick={() => dispatch(PaymentActions.setViewPixKey(true))}
        >
          {t('GENERATE_PIX')
          }</Button>
      </div>
    </div>
  );
}
