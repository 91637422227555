import {handleHeaders} from 'utils/functions';

import {Get, Post} from '../../utils/apiBase';
import {urls} from '../../utils/constants';

const headers = handleHeaders();

export const getProduct = ({code: productCode, params}) => {
  return Post(urls.PRODUCT.replace(':code', productCode), params, headers);
};

export const postOptions = (payload) => {
  return Post(urls.OPTIONS, payload, headers);
};

export const getCheckLinks = ({code: productCode, idAffiliate: affiliate, params}) => {
  return Get(urls.CHECKLINKS.replace(':id', productCode)
    .replace(affiliate ? ':affiliate' : ':affiliate/', affiliate || ''), params, headers);
};

export const postLinkDonation = (payload) => {
  return Post(urls.LINK_DONATION, (payload));
};
