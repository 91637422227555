import cuid from 'cuid';
import {ProductActions} from 'modules/product/redux';
import {useState} from 'react';
import {useDispatch} from 'react-redux';
import {useRouteMatch} from 'react-router-dom';

import useHDNParameters from './useHDNParameters';
import useIsMounted from './useIsMounted';
import useUTMParameters from './useUTMParameters';

function useProduct() {
  const {params} = useRouteMatch();
  const UTMParameters = useUTMParameters();
  const HDNParameters = useHDNParameters();

  const dispatch = useDispatch();
  const isMounted = useIsMounted();
  const [loadingProduct, setLoadingProduct] = useState(false);

  const searchParams = new URLSearchParams(window.location.search);
  const previewMode = Boolean(searchParams.get('preview'));

  const submitProduct = async () => {
    try {
      setLoadingProduct(true);
      const queryString = {
        ...UTMParameters,
        ...HDNParameters,
        x: cuid(),
        preview: previewMode ? previewMode : null,
      };
      dispatch(ProductActions.changeProductLink(params?.code, params?.codeAffiliate, queryString));
      dispatch(ProductActions.setHdnOrigin(HDNParameters));
      dispatch(ProductActions.setUtm(UTMParameters));
    } finally {
      isMounted && setLoadingProduct(false);
    }
  };

  return {loadingProduct, submitProduct};
}

export default useProduct;
