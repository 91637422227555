import React from 'react';

import LoadingImage from '../../assets/images/loading.svg';
import styles from './Loading.module.scss';

const Loading = () => {
  return (
    <div className={styles.container_loading}>
      <img src={LoadingImage} alt='Loading' />
    </div>
  );
};

export default Loading;
