import {PaymentActions} from 'modules/payment/redux';
import {postLinkDonation} from 'modules/product/api';
import {ProductActions} from 'modules/product/redux';
import {useState} from 'react';
import {useDispatch} from 'react-redux';

function useSubmissionDonate() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const submitDonate = async (payload) => {
    try {
      setLoading(true);
      const response = await postLinkDonation(payload);
      dispatch(PaymentActions.getErrorMessage(''));
      const {rev, session, options} = response;
      if (response?.price) {
        dispatch(ProductActions.statusOptions(true));
      }
      dispatch(ProductActions.changeSession({rev, session}));
      const paymentInfo = response?.price ? {...response, rev: undefined, session: undefined} : {};
      dispatch(ProductActions.getErrorDonate(false));
      dispatch(ProductActions.changeOptions(Object.assign({options}, paymentInfo)));
    } catch (error) {
      dispatch(ProductActions.getErrorDonate(true));
    } finally {
      setLoading(false);
    }
  };
  return {loading, submitDonate};
}

export default useSubmissionDonate;
