import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';

import WarningIcon from '../../assets/icons/Warning.svg';
import CaretDown from '../../assets/images/CaretDown.svg';
import CaretUp from '../../assets/images/CaretUp.svg';
import styles from './Initial.module.scss';

const Accordion = ({children, canOpen, initialState, messageError = false, footer = false, className}) => {
  const [activeIndex, setActiveIndex] = useState(initialState);
  const accordionRef = useRef(null);
  const formRef = useRef(null);
  const {t} = useTranslation();

  const handleClick = () => {
    setActiveIndex(!activeIndex);
  };

  useEffect(() => {
    if (canOpen === false) {
      setActiveIndex(true);
    }
  }, [canOpen]);

  return (
    <div style={{marginBottom: footer ? '70px' : '10px'}} className={`${styles.container} ${className ? className : ''}`}>
      {React.Children.map(children, (child, index) => {
        return (
          <div key={index} ref={index === activeIndex ? accordionRef : null}>
            <div onClick={() => handleClick()} className={styles.content}>
              <h3 className={styles.title}>{child.props.title}</h3>
              <div className={styles.icons}>
                {messageError && <img src={WarningIcon} alt='icone error' className={styles.iconError} />}
                <button>{activeIndex ? <img src={CaretUp} alt='Caret up' /> : <img src={CaretDown} alt='Caret down' />}</button>
              </div>
            </div>
            {activeIndex && (
              <div className={styles.children} ref={formRef}>
                {child.props.children}
              </div>
            )}
          </div>
        );
      })}
      {messageError && (
        <div className={styles.msg_error}>
          <span>{t('MESSAGE_ERROR_ACCORD')}</span>
        </div>
      )}
    </div>
  );
};

export default Accordion;
