import ProductSelectors from 'modules/product/selectors';
import React from 'react';
import {useSelector} from 'react-redux';

import IconLoading from '../../assets/images/icon-loading.svg';
import styles from './Button.module.scss';

function Button({withStyle = false, disabled = false, outlined = false, children, ...rest}) {
  const loading = useSelector(ProductSelectors.loading);
  return (
    <button
      type='button'
      disabled={loading ? true : disabled}
      style={{width: withStyle ? '200px' : ''}}
      className={`${styles.btn} ${outlined ? styles.outlined : ''}`}
      {...rest}
    >
      {loading ? <img src={IconLoading} alt='Carregando' /> : children}
    </button>
  );
}

export default Button;
